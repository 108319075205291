export const nameRegex = new RegExp(/[@#%^+={};':"\\|<>\/№]/);
export const subscriptionIdRegex = new RegExp(/^(sub_[a-zA-Z0-9]{24}$)|^$/);
export const quickBooksRecurringTransactionIdRegex = new RegExp(/^(\d{0,5})?$/);
export const locationNameRegex = new RegExp(/[#%^+={};':"\\|<>\/№]/);

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required field';
  }
  if (!values.company) {
    errors.company = 'Required field';
  }
  if (nameRegex.test(values.company)) {
    errors.company = 'Field contains forbidden characters';
  }
  if (!values.LOCATION_POINT_SHORT_INFO) {
    errors.LOCATION_POINT_SHORT_INFO = 'Required field';
  }
  if (!values.POINT_COUNT) {
    errors.POINT_COUNT = 'Load a model to obtain the number of points';
  }
  if (
    values.LOCATION_POINT_SHORT_INFO &&
    values.LOCATION_POINT_SHORT_INFO.length
  ) {
    values.LOCATION_POINT_SHORT_INFO.forEach((point, i) => {
      if (!point) {
        errors[i] = 'Required field';
      }
    });
  }
  if (
    values.START_POINT &&
    values.LOCATION_POINT_SHORT_INFO[values.START_POINT - 1] === undefined
  ) {
    errors.START_POINT = 'Wrong point';
  }
  if (values.INSTANT_PANO && values.START_POINT === '') {
    errors.START_POINT = 'Specify a starting point';
  }

  return errors;
};

export default validate;
