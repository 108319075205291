import * as actionTypes from '../actions/types';

const initialState = {
  name: '',
  locations: [],
  multilocations: [],
  isDownloaded: false,
};

export default function CompanyReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_COMPANY_LOCATIONS: {
      return initialState;
    }
    case actionTypes.LOAD_LOCATIONS_OF_COMPANY: {
      return { ...action.payload, isDownloaded: true };
    }
    default:
      return state;
  }
}
