import * as actions from '../actions/types';

const initialState = {
  show: false,
  label: {
    text: '',
    color: 'black',
  },
  submitButton: {
    text: 'DELETE',
    style: 'danger',
  },
  onSubmit: () => {},
  onClose: () => {},
};

export default function PopupReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SHOW_POPUP:
      return { show: true, ...action.payload };
    case actions.HIDE_POPUP:
      return initialState;
    default:
      return state;
  }
}
