import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import JSZip from 'jszip';

import LogoFileInput from './LogoFileInput';
import FaviconFileInput from './FaviconFileInput';
import SettingsLinks from './SettingsLink';
import SharePopup from './SharePopup';

import validate from './validate';
import { CustomThemes } from '../../../constants';
import { updateSettings, createToast } from '../../../redux/actions';
import API from '../../../shared/utils/API';
import scrollToInvalid from '../../../shared/utils/scrollToInvalid';
import generateGLTF from '../../../shared/utils/generateGLTF';
import { sendPartZip } from '../../../shared/utils/ZipBuilder';
import InfoTooltipIcon from '../../../shared/components/InfoTooltipIcon';
import renderField from '../../../shared/components/form/Field';
import renderCheckbox from '../../../shared/components/form/Checkbox';
import renderSelect from '../../../shared/components/form/Select';
import renderSelectWithSearch from '../../../shared/components/form/SelectWithSearch';
import renderPointFieldsDefault from '../../../shared/components/form/PointFieldsDefault';
import renderInstantPanoRenderPointFields from '../../../shared/components/form/InstantPanoPointFields';
import renderPointFieldsInstant from '../../../shared/components/form/PointFieldsInstant';
import FurnitureFields from '../../../shared/components/form/FurnitureFields';

const toolbarButtons = {
  SCREENSHOT: 'Screenshot',
  MEASURE: 'Measure',
  DOLLHOUSE: 'Dollhouse',
  DOLLHOUSEACTIVE: 'Walkthrough',
  HOTSPOT_MODAL: 'Letter',
  MORE: 'Settings',
  CLOSE: 'Close',
  LOCATIONS: 'Locations',
};

const buildAndSendGLTF = async (pointCount, callback) => {
  pointCount = parseInt(pointCount);

  if (isNaN(pointCount) || pointCount < 0) {
    callback();

    return;
  }

  const ids = Array.from(Array(pointCount).keys()).map(el => el + 1);
  const filesData = generateGLTF(undefined, ids);
  const zip = new JSZip();

  zip.file(filesData[0][0], filesData[0][1]);
  zip.file(filesData[1][0], filesData[1][1]);

  await sendPartZip(zip);

  callback();
};

const initialLink = {
  fullscreen: false,
  autoFollow: true,
  autoRotate: true,
  autoPlay: false,
  locationName: '',
  panelColor: '#000000',
};

const SettingsForm = ({
  loadLocationDetails,
  locationId,
  handleSubmit,
  updateParentGLTFFlagHandler,
  dispatch,
  initialValues,
  points,
  instantPanoMinimapPoints,
  furnitureItems,
  hotspotItems,
  hotspotList,
  modelParsed,
  onPointCountChange,
  location,
  match,
  showSharePopup,
  setShowSharePopup,
  companies,
  companyData,
}) => {
  const id = match.params.id;

  const [showPoints, setShowPoints] = useState(false);
  const [showMinimapPoints, setShowMinimapPoints] = useState(false);
  const [showFurniture, setShowFurniture] = useState(false);
  const [showBranding, setShowBranding] = useState(false);
  const [showTechSettings, setShowTechSettings] = useState(false);
  const [settingsLink, setSettingsLink] = useState({
    ...initialLink,
    locationName: location.data.name,
  });
  const [isMain, setIsMain] = useState(initialValues.IS_MAIN);
  const [instantPanoPointsCount, setInstantPanoPointsCount] = useState(
    initialValues.INSTANT_PANO_POINTS_COUNT
  );
  const [logoUploading, setLogoUploading] = useState(false);
  const [faviconUploading, setFaviconUploading] = useState(false);
  const [useTiledCubemaps, setUseTiledCubemaps] = useState(
    initialValues.USE_TILED_CUBEMAPS
  );

  const isInteractiveFloorPlan = initialValues.IS_INTERACTIVE_PLAN;

  const instantPanoOnly = initialValues.INSTANT_PANO_ONLY;

  const onSubmit = e => {
    if (e) e.preventDefault();

    handleSubmit(e);
  };

  useEffect(() => {
    if (points.length) {
      const array = points.map((point, i) => {
        const initial = initialValues.LOCATION_POINT_SHORT_INFO[i];

        return {
          name: initial ? initial.name : `Point №${i + 1}`,
          x: initial ? initial.x : 0,
          y: initial ? initial.y : 0,
          cam: { ...point.position },
          floor: point.position.y > 300 ? 2 : 1,
          noImage: point.noImage,
          target: initial ? initial.target : undefined,
          icon: initial ? initial.icon : undefined,
          hotspotId: initial
            ? initial.hotspotId
            : point.hotspotId
              ? point.hotspotId
              : undefined,
          portalId: initial ? initial.portalId : undefined,
          portalPoint: initial
            ? initial.portalPoint
            : point.noImage
              ? i + 1
              : undefined,
          markers: initial ? initial.markers : [],
        };
      });

      dispatch(change('update_location', 'POINT_COUNT', points.length));
      dispatch(change('update_location', 'LOCATION_POINT_SHORT_INFO', array));
      dispatch(
        change('update_location', 'INSTANT_PANO_POINTS_COUNT', points.length)
      );
    }
  }, [JSON.stringify(points)]);

  useEffect(() => {
    dispatch(change('update_location', 'INSTANT_PANO_ONLY', instantPanoOnly));
  }, [instantPanoOnly]);

  useEffect(() => {
    if (modelParsed) {
      onSubmit();

      setTimeout(() => {
        updateParentGLTFFlagHandler();
      }, 2000);
    }
  }, [modelParsed]);

  // useEffect(() => {
  //   if (!isMain) dispatch(change('update_location', 'IS_PAID', false));
  // }, [isMain])

  // useEffect(() => {
  //   if (points.length) {
  //     const array = points.map((point, i) => {
  //       const initial = initialValues.LOCATION_POINT_SHORT_INFO[i];

  //       return {
  //         name: initial ? initial.name : `Point №${i + 1}`,
  //         x: initial ? initial.x : 0,
  //         y: initial ? initial.y : 0,
  //         cam: { ...point.position },
  //         floor: point.position.y > 300 ? 2 : 1,
  //         noImage: point.noImage,
  //         target: initial ? initial.target : undefined,
  //         icon: initial ? initial.icon : undefined,
  //         hotspotId: initial ? initial.hotspotId : point.hotspotId ? point.hotspotId : undefined,
  //         portalId: initial ? initial.portalId : undefined,
  //         portalPoint: initial
  //           ? initial.portalPoint
  //           : point.noImage
  //           ? i + 1
  //           : undefined,
  //         markers: initial ? initial.markers : [],
  //       };
  //     });
  //     dispatch(change('update_location', 'POINT_COUNT', points.length));
  //     dispatch(change('update_location', 'LOCATION_POINT_SHORT_INFO', array));
  //     dispatch(change('update_location', 'INSTANT_PANO_POINTS_COUNT', points.length));
  //   }
  // }, [points]);

  useEffect(() => {
    if (instantPanoMinimapPoints.length) {
      const array = instantPanoMinimapPoints.map((point, i) => {
        const initial = initialValues.INSTANT_PANO_LOCATION_POINT_SHORT_INFO[i];

        point.position = { x: Number(initial.x), y: Number(initial.y) };

        return {
          name: initial ? initial.name : `Point №${i + 1}`,
          x: initial ? initial.x : 0,
          y: initial ? initial.y : 0,
          cam: { ...point.position },
          floor: point.position.y > 300 ? 2 : 1,
          noImage: point.noImage,
          target: initial ? initial.target : undefined,
          icon: initial ? initial.icon : undefined,
          hotspotId: initial
            ? initial.hotspotId
            : point.hotspotId
              ? point.hotspotId
              : undefined,
          portalId: initial ? initial.portalId : undefined,
          portalPoint: initial
            ? initial.portalPoint
            : point.noImage
              ? i + 1
              : undefined,
          scale: initial ? initial.scale : 1,
          markers: initial ? initial.markers : [],
        };
      });

      dispatch(
        change(
          'update_location',
          'INSTANT_PANO_LOCATION_POINT_SHORT_INFO',
          array
        )
      );
    }
  }, [instantPanoMinimapPoints]);

  useEffect(() => {
    if (instantPanoOnly && instantPanoPointsCount !== null) {
      onPointCountChange(instantPanoPointsCount);
    }
  }, [instantPanoPointsCount, instantPanoOnly]);

  useEffect(() => {
    if (furnitureItems.length) {
      const initial = initialValues.FURNITURE;
      const result = {};
      furnitureItems.forEach(id => {
        result[id] = initial && initial[id] ? initial[id] : null;
      });
      dispatch(change('update_location', 'FURNITURE', result));
    }
  }, [furnitureItems]);

  // useEffect(() => {
  //   try {
  //     let date;
  //
  //     if(!location.data.description.CREATED_AT){
  //       date = location.data.createdAt.split('T')[0]
  //     }
  //     else{
  //       const dateSplit = location.data.description.CREATED_AT.split('-')
  //
  //       if(dateSplit[1].length === 1){
  //         dateSplit[1] = `0${dateSplit[1]}`
  //       }
  //
  //       if(dateSplit[2].length === 1){
  //         dateSplit[2] = `0${dateSplit[2]}`
  //       }
  //
  //       date = dateSplit.join('-')
  //     }
  //
  //     dispatch(change('update_location', 'CREATED_AT', date));
  //   }
  //   catch (e) {
  //
  //   }
  // }, []);

  useEffect(() => {
    if (hotspotItems.length) {
      const initial = initialValues.HOTSPOT;
      const result = {};
      hotspotItems.forEach(id => {
        result[id] = initial && initial[id] ? initial[id] : null;
      });
      dispatch(change('update_location', 'HOTSPOT', result));
    }
  }, [hotspotItems]);

  const createLink = () =>
    Object.keys(settingsLink).reduce((acc, cur) => {
      return acc + `&${cur}=${encodeURIComponent(settingsLink[cur])}`;
    }, `${process.env.REACT_APP_PLAYER_URL}/?locationId=${locationId}`);

  const createFramesLink = () => {
    return `${process.env.REACT_APP_PLAYER_URL}/spinner-360?locationId=${locationId}`;
  };

  const updateLogoHandler = useCallback(
    async file => {
      const { image, type } = file.logo[0];
      setLogoUploading(true);
      try {
        if (image) {
          const {
            editorNeedSync,
            INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
            LOCATION_POINT_SHORT_INFO,
            NORTH_DIRECTION,
          } = await API.getLocationDescription(id);

          const data = {
            id: location.data.id,
            file: image.slice(image.indexOf(',') + 1),
            type,
          };
          await API.request(API.endpoints.UPLOAD_LOGO, data);
          await API.request(API.endpoints.UPDATE_LOCATION_FULL, {
            locationId: location.data.id,
            name: location.data.name,
            companyName: location.data.company_name,
            description: `'${JSON.stringify({
              ...location.data.description,
              ...(editorNeedSync
                ? {
                    LOCATION_POINT_SHORT_INFO,
                    INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
                    NORTH_DIRECTION,
                  }
                : {}),
              LOGO_IMAGE: `locations/${location.data.id}/logo.svg`,
              editorNeedSync: false,
            })}'`,
          });
          updateSettings({ hash: String(Date.now()) });
          setLogoUploading(false);
          loadLocationDetails(id);
          dispatch(createToast('SUCCESS', 'Logo image has been uploaded'));
        } else {
          throw new Error(`updateLogoHandler: something wrong with an image`);
        }
      } catch (error) {
        console.error(error);
        setLogoUploading(false);
        dispatch(createToast('ERROR', error.toString()));
      }
    },
    [location]
  );

  const updateFaviconHandler = useCallback(
    async file => {
      const { image, type } = file.favicon[0];
      setFaviconUploading(true);
      try {
        if (image) {
          const {
            editorNeedSync,
            INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
            LOCATION_POINT_SHORT_INFO,
            NORTH_DIRECTION,
          } = await API.getLocationDescription(id);
          const data = {
            id: location.data.id,
            file: image.slice(image.indexOf(',') + 1),
            type,
          };
          await API.request(API.endpoints.UPLOAD_FAVICON, data);
          await API.request(API.endpoints.UPDATE_LOCATION_FULL, {
            locationId: location.data.id,
            name: location.data.name,
            companyName: location.data.company_name,
            description: `'${JSON.stringify({
              ...location.data.description,
              ...(editorNeedSync
                ? {
                    LOCATION_POINT_SHORT_INFO,
                    INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
                    NORTH_DIRECTION,
                  }
                : {}),
              FAVICON_IMAGE: `locations/${location.data.id}/favicon.png`,
              editorNeedSync: false,
            })}'`,
          });
          updateSettings({ hash: String(Date.now()) });
          setFaviconUploading(false);
          loadLocationDetails(id);
          dispatch(createToast('SUCCESS', 'Favicon image has been uploaded'));
        } else {
          console.info('image:', image);
          throw new Error(
            `updateFaviconHandler: something wrong with an image`
          );
        }
      } catch (error) {
        console.error(error);
        setFaviconUploading(false);
        dispatch(createToast('ERROR', error.toString()));
      }
    },
    [location]
  );

  // useEffect(() => {
  //   if(useEquirectangular) {
  //     dispatch(change('update_location', 'USE_TILED_CUBEMAPS', false));
  //   } else {
  //     dispatch(change('update_location', 'USE_TILED_CUBEMAPS', true));
  //   }
  // }, [useEquirectangular]);

  // useEffect(() => {
  //   if(useTiledCubemaps) {
  //     dispatch(change('update_location', 'USE_EQUIRECTANGULAR', false));
  //   } else {
  //     dispatch(change('update_location', 'USE_EQUIRECTANGULAR', true));
  //   }
  // }, [useTiledCubemaps]);

  return (
    <Row className="mb-3">
      <Col>
        <Form onSubmit={onSubmit}>
          <Field
            name="LOCATION_NAME"
            type="text"
            label="Location name"
            component={renderField}
          />
          <Field
            name="COMPANY_NAME"
            initialValue={initialValues.COMPANY_NAME || 'no companies'}
            label="Company name"
            options={companies.value.map((company, index) => {
              return {
                name: company.name,
                value: company.name,
                key: `${index}c`,
              };
            })}
            component={renderSelectWithSearch}
            // isEmpty={companies.value.length === 0}
          />
          <Field
            name="STATUS"
            initialValue={initialValues.STATUS || 'ACTIVE'}
            label="Status"
            options={[
              { name: 'ACTIVE', value: 'ACTIVE', key: `ACTIVE` },
              { name: 'DISABLED', value: 'DISABLED', key: `DISABLED` },
            ]}
            component={renderSelect}
          />
          {!isInteractiveFloorPlan && (
            <>
              <Field
                name="LOCATION_VARIANT_NAME"
                type="text"
                label="Location variant name"
                component={renderField}
              />
              <Field
                name="LOCATION_LIST_NAME"
                type="text"
                label="Name in property locations panel"
                component={renderField}
              />
              <Field
                name="LIST_ID"
                label={
                  <span>
                    Property ID{' '}
                    <InfoTooltipIcon tooltip="Locations with the same name are combined into a property. An optional field" />
                  </span>
                }
                initialValue={
                  initialValues.LIST_ID || 'Property ID is not selected'
                }
                options={companyData.multilocations.map((m, index) => ({
                  name: m.name,
                  value: m.name,
                  key: `${index}m`,
                }))}
                component={renderSelectWithSearch}
              />
              <Field
                name="IS_MAIN"
                type="checkbox"
                label="Main location"
                component={renderCheckbox}
                onChange={(event, value) => setIsMain(value)}
              />
              <Field
                name="IS_SIGHTMAP_VIEW"
                type="checkbox"
                label="Location for sightmap"
                component={renderCheckbox}
              />
              <Field
                name="IS_LOCATION_HIDDEN"
                type="checkbox"
                label="Hide location"
                component={renderCheckbox}
              />
              <Field
                name="INTERACTIVE_FLOORPLAN_ID"
                type="number"
                label="Interactive floorplan id"
                component={renderField}
              />
              {/* <Field
                name='CREATED_AT'
                type='date'
                label='Creation Date'
                component={renderField}
              /> */}
              <Field
                name="LOCATION_PRIORITY"
                type="number"
                label="Priority of location in the property list"
                component={renderField}
              />
              {/* <Field */}
              {/*  name='IS_PAID' */}
              {/*  type='checkbox' */}
              {/*  label='Paid' */}
              {/*  component={renderCheckbox} */}
              {/*  hide={!isMain} */}
              {/* /> */}
              <Field
                name="LOCATION_SHORT_INFO"
                type="text"
                label="Short description"
                component={renderField}
                hide={true}
              />
              <Field
                name="LOCATION_DESCRIPTION"
                type="text"
                as="textarea"
                label="Detailed description"
                component={renderField}
                hide={true}
              />
              {/* <Field
            name='LOCATION_ADDRESS'
            type='text'
            label='Location address'
            component={renderField}
            hide
          /> */}
              <Field
                name="LOCATION_AREA"
                type="text"
                label="Location area"
                component={renderField}
                hide={true}
              />
              {/* <Field
            name='INSTANT_PANO'
            type='checkbox'
            label='Instant pano'
            component={renderCheckbox}
          /> */}
              {/* <Field
            name='INSTANT_PANO_ONLY'
            type='checkbox'
            label='Photo tour'
            component={renderCheckbox}
            onChange={(event, value) => {
              onChangePhotoTour(true);
              setInstantPanoOnly(true)
            }}
            validate.js={[]}
          /> */}
              {/* <div className="form-group row">
            <label className="form-label col-form-label col-sm-3">Photo Tour</label>
            <div className="col-sm-9">
              <input
                aria-label="3D tour"
                type="checkbox"
                ref={instantPanoOnlyEnableRef}
                onChange={() => {
                  onChangePhotoTour(true);
                  setInstantPanoOnly(true);
                }}
              />
            </div>
          </div> */}
              {/* <div className="form-group row">
            <label className="form-label col-form-label col-sm-3">3D Tour</label>
            <div className="col-sm-9">
              <input
                aria-label="3D tour"
                type="checkbox"
                ref={instantPanoOnlyDisableRef}
                onChange={() => {
                  onChangePhotoTour(false);
                  setInstantPanoOnly(false);
                }}
              />
            </div>
          </div> */}

              {/* <Field
            name='INSTANT_PANO_ONLY'
            type='checkbox'
            label='3D tour'
            component={renderCheckbox}
            onChange={(event, value) => {
              onChangePhotoTour(false);
              setInstantPanoOnly(false)
            }}
            validate.js={[]}
          /> */}
              {/* <Field
            name='INSTANT_PANO_ONLY'
            type='checkbox'
            label='Photo tour'
            component={renderCheckbox}
          /> */}

              {/* <Field
            name='FLOORS'
            type='checkbox'
            label='Two floors'
            component={renderCheckbox}
          /> */}
              <Field
                name="START_POINT"
                type="number"
                label="Start point"
                component={renderField}
              />
              {/*
            Points
            ============================
            */}
              {/*           <Form.Group as={Row}>
            <Form.Label column sm={3}>
              Minimap dots setting{' '}
            </Form.Label>
            <div className="col-sm-9">
              <Button
                // variant={`${!showPoints ? 'outline-' : ''}success`}
                onClick={() => setShowPoints(!showPoints)}
                size="sm"
              >
                {showPoints ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Button>
            </div>
          </Form.Group> */}

              {/* {instantPanoOnly && <> */}
              {/*  <Form.Group as={Row}> */}
              {/*    <Form.Label column sm={3}> */}
              {/*      Points customizing for the minimap{' '} */}
              {/*      <InfoTooltipIcon tooltip='Points coordinates customizing for the minimap' /> */}
              {/*    </Form.Label> */}
              {/*    <div className='col-sm-9'> */}
              {/*      <Button */}
              {/*        onClick={() => setShowMinimapPoints(!showMinimapPoints)} */}
              {/*        size='sm' */}
              {/*      > */}
              {/*        {showMinimapPoints ? <ChevronUpIcon /> : <ChevronDownIcon />} */}
              {/*      </Button> */}
              {/*    </div> */}
              {/*  </Form.Group> */}
              {/*  <FieldArray */}
              {/*    name='INSTANT_PANO_LOCATION_POINT_SHORT_INFO' */}
              {/*    component={renderInstantPanoRenderPointFields} */}
              {/*    show={showMinimapPoints} */}
              {/*  /> */}
              {/* </>} */}
              {/*
            Hotspots
            ============================
          */}

              {/* <Form.Group as={Row}>
            <Form.Label column sm={3}>
              Hotspots configuration{' '}
              <InfoTooltipIcon tooltip='Hotspots configuration for icons' />
            </Form.Label>
            <div className='col-sm-9'>
              <Button onClick={() => setShowHotspots(!showHotspots)} size="sm">
                {showHotspots ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Button>
            </div>
          </Form.Group>

          {instantPanoOnly ? (
            <FieldArray
              name="LOCATION_POINT_SHORT_INFO"
              component={renderPointFieldsHotspotInstant}
              show={showHotspots}
            />
          ) : (
            <FieldArray
              name="LOCATION_POINT_SHORT_INFO"
              component={renderPointFieldsHotspotDefault}
              show={showHotspots}
            />
          )}
          <Col>
            <Form.Group as={Row}>
              <Link to={`/hotspot/${locationId}`}>
                <Button>
                  Hotspots creation</Button>
              </Link>
            </Form.Group>
          </Col> */}
              <Field
                name="TOOLBAR_VALUE"
                label="Map Button Hotspot or URL"
                placeholder="Hotspot ID or URL"
                min={0}
                component={renderField}
              />
              <Field
                name="LEASE_HOTSPOT"
                type="text"
                label="Lease Button Hotspot"
                placeholder="Hotspot ID or URL"
                component={renderField}
              />
              <Field
                name="LEASE_TEXT"
                type="text"
                label="Text for Lease Button"
                component={renderField}
              />
              {/* <PointFieldsHotspot
                fields={hotspotItems || []}
                options={hotspotList || []}
                show={showHotspots} />

                           <HotspotFields
                items={hotspotItems}
                options={hotspotList}
                show={showHotspots} />   */}

              {/*
            Furniture
            ============================
          */}
              {!instantPanoOnly && furnitureItems.length > 0 && (
                <React.Fragment>
                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                      Furniture customization{' '}
                      <InfoTooltipIcon tooltip="Customizing the data displayed by clicking on the furniture" />
                    </Form.Label>
                    <div className="col-sm-9">
                      <Button
                        onClick={() => setShowFurniture(!showFurniture)}
                        size="sm"
                      >
                        {showFurniture ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        )}
                      </Button>
                    </div>
                  </Form.Group>
                  <FurnitureFields
                    locationId={locationId}
                    furniture={initialValues.FURNITURE}
                    items={furnitureItems}
                    options={
                      hotspotList /* .filter(h => h.location_id === locationId) */
                    }
                    show={showFurniture}
                  />
                </React.Fragment>
              )}
              <Field
                name="VARIANTS[0]"
                type="number"
                label="Location option"
                component={renderField}
              />
              <Field
                name="VARIANTS[1]"
                type="number"
                label="Location option"
                component={renderField}
              />
              <Field
                name="SHOW_MAP"
                type="checkbox"
                label="Display the map"
                component={renderCheckbox}
                checked={true}
                hide
              />
              {/* <Field
             name='ACCENT_COLOR'
             type='text'
             label='Main tour color'
             placeholder='#36A6FD'
             component={renderField}
          /> */}

              <Form.Group as={Row}>
                <Form.Label column sm={3}>
                  Branding <InfoTooltipIcon tooltip="Branding" />
                </Form.Label>
                <div className="col-sm-9">
                  <Button
                    onClick={() => setShowBranding(!showBranding)}
                    size="sm"
                  >
                    {showBranding ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </Button>
                </div>
              </Form.Group>

              {showBranding && (
                <div
                  className="mt-4 mb-4 pt-4 pb-3 pl-4 pr-4 collapse show"
                  style={{ backgroundColor: 'rgb(244, 244, 244)' }}
                >
                  <SettingsLinks
                    locationId={id}
                    settingsLink={settingsLink}
                    setSettingsLink={setSettingsLink}
                  />
                  <Field
                    name="CUSTOM_THEME"
                    as="select"
                    label="Style"
                    component={renderField}
                  >
                    <option value="">Standard</option>
                    <option value={CustomThemes.LUNAS}>LUNAS</option>
                  </Field>
                  {!instantPanoOnly && (
                    <Field
                      name="SHOW_MEASURE_DISTANCE"
                      type="checkbox"
                      label="Display the ruler"
                      component={renderCheckbox}
                    />
                  )}
                  <Field
                    name="HIDE_BOTTOM_LINE"
                    type="checkbox"
                    label={`Hide the bottom line "Powered by"`}
                    component={renderCheckbox}
                  />
                  <Field
                    name="DISABLE_LINK"
                    type="checkbox"
                    label="Disalle the link"
                    component={renderCheckbox}
                  />
                  <Field
                    name="MEASURE_USE_FEET"
                    type="checkbox"
                    label="Use feet"
                    component={renderCheckbox}
                  />
                  {/* <Field
              name='TRUNCATE_DOCUMENT_TITLE'
              type='checkbox'
              label='Short version of the tab header'
              component={renderCheckbox}
            /> */}
                  <Field
                    name="DOCUMENT_TITLE"
                    type="text"
                    label={<span>Document header </span>}
                    placeholder="Document header"
                    component={renderField}
                  />
                  {Object.entries(toolbarButtons).map(([key, value]) => {
                    return (
                      <Field
                        key={key}
                        name={'TOOLBAR_TOOLTIP_' + key}
                        label={
                          'Tooltip icon in the toolbar ' + '"' + value + '"'
                        }
                        placeholder=" "
                        component={renderField}
                      />
                    );
                  })}
                  <LogoFileInput
                    onSubmit={updateLogoHandler}
                    uploading={logoUploading}
                    image={location.data.description.LOGO_IMAGE}
                  />
                  <FaviconFileInput
                    onSubmit={updateFaviconHandler}
                    uploading={faviconUploading}
                    image={location.data.description.FAVICON_IMAGE}
                  />
                </div>
              )}

              <Form.Group as={Row}>
                <Form.Label column sm={3}>
                  Tech. settings <InfoTooltipIcon tooltip="Tech. settings" />
                </Form.Label>
                <div className="col-sm-9">
                  <Button
                    onClick={() => setShowTechSettings(!showTechSettings)}
                    size="sm"
                  >
                    {showTechSettings ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </Button>
                </div>
              </Form.Group>

              {showTechSettings && (
                <div
                  className="mt-4 mb-4 pt-4 pb-3 pl-4 pr-4 collapse show"
                  style={{ backgroundColor: 'rgb(244, 244, 244)' }}
                >
                  <Field
                    name="USE_4K_TILES"
                    type="checkbox"
                    label="Use 4096x4096 resolution when zooming (if available)"
                    component={renderCheckbox}
                  />
                  <Field
                    name="PRELOAD_CAMERA_DIRECTION"
                    type="checkbox"
                    label="Preupload visible sides of neighboring points in higher resolution"
                    component={renderCheckbox}
                  />
                  <Field
                    name="ORBIT_MAX_DISTANCE"
                    type="text"
                    label="Maximum camera distance in the dollhouse"
                    placeholder="1500"
                    component={renderField}
                  />
                  <Field
                    name="ORBIT_MIN_DISTANCE"
                    type="text"
                    label="Minimum camera distance in the dollhouse"
                    placeholder="700"
                    component={renderField}
                  />
                  <Field
                    name="FAR_CLIPPING"
                    type="text"
                    label="Far clipping"
                    placeholder="4000"
                    component={renderField}
                  />

                  {/* navigation-related */}

                  <Field
                    name="IS_ARROWS_ENABLED"
                    type="checkbox"
                    label="Display arrows in the player"
                    component={renderCheckbox}
                  />
                  {!instantPanoOnly && (
                    <>
                      <Field
                        name="IS_CURSOR_POINTS_ENABLED"
                        type="checkbox"
                        label="Display the cursor"
                        component={renderCheckbox}
                      />
                      <Field
                        name="IS_DOLL_HOUSE_ENABLED"
                        type="checkbox"
                        label="Display the dollhouse"
                        component={renderCheckbox}
                      />
                    </>
                  )}
                  <Field
                    name="IS_ARROWLESS_NAVIGATION_ENABLED"
                    type="checkbox"
                    label="Enable pano sectors navigation"
                    component={renderCheckbox}
                  />
                  {!instantPanoOnly && (
                    <>
                      <Field
                        name="IS_FLOOR_POINTS_ENABLED"
                        type="checkbox"
                        label="Enable 3D tour floor points"
                        component={renderCheckbox}
                      />
                      <Field
                        name="IS_3D_TOUR_TRIGGERS_ENABLED"
                        type="checkbox"
                        label="Enable 3D tour triggers"
                        component={renderCheckbox}
                      />
                    </>
                  )}

                  <Field
                    name="AUTOROTATION"
                    type="checkbox"
                    label="Enable autorotation"
                    component={renderCheckbox}
                  />
                  <Field
                    name="AUTOFOLLOW"
                    type="checkbox"
                    label="Enable autofollow"
                    component={renderCheckbox}
                  />
                  <Field
                    name="FIELD_OF_VIEW"
                    type="number"
                    label="Field of view"
                    placeholder={80}
                    component={renderField}
                  />

                  {!instantPanoOnly && (
                    <>
                      <Field
                        name="POINT_COUNT"
                        type="number"
                        label="Points count"
                        component={renderField}
                        readOnly
                      />
                    </>
                  )}

                  <Field
                    name="NOT_USE_AUDIO"
                    type="checkbox"
                    label="Do not use audio"
                    component={renderCheckbox}
                  />

                  {/* <Form.Group as={Row}> */}
                  {/*  <Form.Label column sm={3}> */}
                  {/*    Customization {instantPanoOnly ? 'icons' : 'points'}{' '} */}
                  {/*    <InfoTooltipIcon tooltip='Description of points, setting coordinates for the minimap and transitions' /> */}
                  {/*  </Form.Label> */}
                  {/*  <div className='col-sm-9'> */}
                  {/*    <Button */}
                  {/*      // variant={`${!showPoints ? 'outline-' : ''}success`} */}
                  {/*      onClick={() => setShowPoints(!showPoints)} */}
                  {/*      size='sm' */}
                  {/*    > */}
                  {/*      {showPoints ? <ChevronUpIcon /> : <ChevronDownIcon />} */}
                  {/*    </Button> */}
                  {/*  </div> */}
                  {/* </Form.Group> */}
                  {instantPanoOnly ? (
                    <FieldArray
                      name="LOCATION_POINT_SHORT_INFO"
                      component={renderPointFieldsInstant}
                      show={showPoints}
                    />
                  ) : (
                    <FieldArray
                      name="LOCATION_POINT_SHORT_INFO"
                      component={renderPointFieldsDefault}
                      show={showPoints}
                    />
                  )}
                </div>
              )}

              {/* <Form.Group as={Row}> */}
              {/*  <Form.Label column sm={3}> */}
              {/*    Customization {instantPanoOnly ? 'icons' : 'points'}{' '} */}
              {/*    <InfoTooltipIcon tooltip='Description of points, setting coordinates for the minimap and transitions' /> */}
              {/*  </Form.Label> */}
              {/*  <div className='col-sm-9'> */}
              {/*    <Button */}
              {/*      // variant={`${!showPoints ? 'outline-' : ''}success`} */}
              {/*      onClick={() => setShowPoints(!showPoints)} */}
              {/*      size='sm' */}
              {/*    > */}
              {/*      {showPoints ? <ChevronUpIcon /> : <ChevronDownIcon />} */}
              {/*    </Button> */}
              {/*  </div> */}
              {/* </Form.Group> */}
              {instantPanoOnly ? (
                <FieldArray
                  name="LOCATION_POINT_SHORT_INFO"
                  component={renderPointFieldsInstant}
                  show={showPoints}
                />
              ) : (
                <FieldArray
                  name="LOCATION_POINT_SHORT_INFO"
                  component={renderPointFieldsDefault}
                  show={showPoints}
                />
              )}
            </>
          )}
          {isInteractiveFloorPlan && (
            <>
              <Field
                name="FLOOR_PLAN_AUTOROTATION"
                type="checkbox"
                label="Enable autorotation"
                component={renderCheckbox}
              />
              <Field
                name="USE_CUSTOM_LEVELS_NAMES"
                label="Use custom levels names"
                type="checkbox"
                component={renderCheckbox}
              />
              <Field
                name="TOUR"
                type="text"
                label="ID or URL for Virtual Tour button"
                placeholder="Tour ID or URL"
                component={renderField}
              />
            </>
          )}
          <Button
            variant="primary"
            type="submit"
            className="d-none"
            id="submit-settings-button"
          >
            Update settings
          </Button>
          <SharePopup
            show={showSharePopup}
            text={`<iframe ${isInteractiveFloorPlan ? '' : 'allowfullscreen'} title='view_player' src='${isInteractiveFloorPlan ? createFramesLink() : createLink()}' width='800' height='500'></iframe>`}
            onClose={() => setShowSharePopup(false)}
          />
        </Form>
      </Col>
    </Row>
  );
};

SettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  points: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'update_location',
  onSubmitFail: errors => scrollToInvalid(errors),
  validate,
  enableReinitialize: true,
})(SettingsForm);
