import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import FurnitureSettings from './FurnitureSettings';

const FurnitureModal = ({
  show,
  edit,
  furniture = {},
  uploading,
  onSubmit,
  onClose,
}) => {
  return (
    <div>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Furniture</Modal.Title>
        </Modal.Header>
        <FurnitureSettings
          onSubmit={onSubmit}
          initialValues={furniture}
          uploading={uploading}
          edit={edit}
        />
      </Modal>
    </div>
  );
};

FurnitureModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FurnitureModal;
