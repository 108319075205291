import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import FileInput from '../../../shared/components/form/FileInput';

const AudioFileInput = ({ onSubmit, uploading, audio }) => {
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  return (
    <Row>
      <Col>
        <FileInput
          label={
            <h5>
              Audio <span className="text-muted">(.mp3, .wav)</span>
            </h5>
          }
          accept=".mp3,.wav"
          onSubmit={onSubmit}
          onFileSelect={() => setSubmitButtonDisabled(false)}
          submitButtonLabel={'Upload'}
          submitButtonDisabled={uploading ? true : submitButtonDisabled}
        />
        {!!audio && (
          <audio controls src={`${process.env.REACT_APP_MEDIA_URL}/${audio}`}>
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        )}
      </Col>
    </Row>
  );
};

AudioFileInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
};

AudioFileInput.defaultProps = {
  uploading: false,
};

export default AudioFileInput;
