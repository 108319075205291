import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Form, Button, CardGroup, Modal } from 'react-bootstrap';

import renderDropZoneField from '../../../shared/components/form/DropZone';
import validate from './validate';
import renderField from '../../../shared/components/form/Field';

const FurnitureSettings = ({
  handleSubmit,
  edit,
  initialValues,
  uploading,
}) => {
  const [imageSelected, setImageSelected] = useState(null);

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <Field name="name" type="text" label="Name" component={renderField} />
        <Field name="link" type="text" label="Link" component={renderField} />
        <Field name="price" type="text" label="Price" component={renderField} />
        <Field
          name="label"
          type="text"
          label="Button text"
          placeholder="Purchase"
          component={renderField}
        />
        <Row>
          <Col>
            <CardGroup>
              <Field
                name="image"
                component={renderDropZoneField}
                image={
                  initialValues && initialValues.image
                    ? `${process.env.REACT_APP_MEDIA_URL}/${initialValues.image}`
                    : ''
                }
                accept=".jpg,.png"
                style={{ width: '100%', maxWidth: '500px' }}
                onChange={() => setImageSelected(true)}
              />
            </CardGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          disabled={
            uploading ||
            !(imageSelected || (initialValues && initialValues.image))
          }
        >
          {edit ? 'Done' : 'Add'}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

FurnitureSettings.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'update_furniture',
  validate,
})(FurnitureSettings);
