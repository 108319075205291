import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as zipjs from '@zip.js/zip.js';
import { Col, Row, Button } from 'react-bootstrap';

import FileInput from '../../../shared/components/form/FileInput';
import { validateFramesZip } from '../../../shared/utils/validate';
import AlertTooltipIcon from '../../../shared/components/AlertTooltipIcon';
import InfoTooltipIcon from '../../../shared/components/InfoTooltipIcon';
import { createToast } from '../../../redux/actions';
import API from '../../../shared/utils/API';

// Styled components for input and checkbox
const StyledInput = styled.input`
  width: 120px;
  height: 30px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  :focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const StyledCheckbox = styled(StyledInput)`
  width: 12px;
  height: 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  :focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

/**
 * FrameZipInput component for uploading zip files containing frames and managing levels.
 * 
 * @param {function} onSubmit - Callback function to handle form submission with file data and levels.
 * @param {function} onFileSelect - Callback function to handle file selection with file data and selected level.
 * @param {boolean} allowUploading - Prop to control whether uploading is allowed (defaults to false).
 * @param {boolean} uploading - Prop to indicate if a file is currently uploading (defaults to false).
 * @param {boolean} uploaded - Prop to indicate if frames have been uploaded (defaults to false).
 * @param {string} error - Prop containing any error message (defaults to an empty string).
 * @param {function} dispatch - Redux dispatch function.
 * @param {number} id - Location ID prop.
 * @param {function} updateLevelsData - Callback function to handle deletion.
 * @param {array} levels - Array of level objects containing properties like number, customName, scale, and reverseRotation.
 * @param {function} setLevels - Function to update the levels state.
 * 
 * @returns {JSX} React component for uploading frames and managing levels.
 */
const FrameZipInput = ({
  onSubmit,
  onFileSelect,
  allowUploading,
  uploading,
  framesUploaded,
  uploaded,
  error,
  dispatch,
  id,
  updateLevelsData,
  setFramesUploading,
  levels,
  setLevels,
}) => {
  const [equiFileNamesServerContains, setEquiFileNamesServerContains] =
    useState([]);

  const [selectedLevel, setSelectedLevel] = useState(
    levels.length > 0 ? levels[levels.length - 1].number + 1 : 0
  );

  /**
   * Asynchronous function to convert an image file to WebP format.
   *
   * @param {File} file - The image file to convert.
   *
   * @returns {Promise<Blob>} Promise resolving to a Blob containing the converted WebP image data.
   */
  const convertToWebP = async file => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(function (blob) {
          resolve(blob);
        }, 'image/webp');
      };
      img.src = URL.createObjectURL(file);
    });
  };

  /**
   * Asynchronous function to create an image object from a Blob representing the image data.
   *
   * @param {Blob} imageBlob - The Blob containing the image data.
   *
   * @returns {Promise<Image>} Promise resolving to an Image object representing the image.
   */
  async function createImage(imageBlob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        const arrayBuffer = event.target.result;
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = err => reject(err);
        image.src = window.URL.createObjectURL(
          new Blob([arrayBuffer], { type: imageBlob.type })
        );
      };
      reader.onerror = err => reject(err);
      reader.readAsArrayBuffer(imageBlob);
    });
  }

  /**
   * Asynchronous function to create a low-quality WebP image from a high-quality image.
   *
   * @param {Blob} imageBlob - The Blob containing the high-quality image data.
   *
   * @returns {Promise<Blob>} Promise resolving to a Blob containing the low-quality WebP image data.
   */
  async function createLowQualityImage(imageBlob) {
    const image = await createImage(imageBlob);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const width = image.width;
    const height = image.height;

    const reducedWidth = Math.floor(width / 4);
    const reducedHeight = Math.floor(height / 4);
    canvas.width = reducedWidth;
    canvas.height = reducedHeight;

    ctx.drawImage(
      image,
      0,
      0,
      width,
      height,
      0,
      0,
      reducedWidth,
      reducedHeight
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => resolve(blob), 'image/webp', 0.8);
    });
  }

  /**
   * Asynchronous function to handle frames upload, including processing archive files,
   * creating low-quality versions, and adding them to a new zip.
   *
   * @param {array} files - Array of uploaded files.
   * @param {string} parentFolderName - Name of the parent folder for the uploaded zip.
   */
  const handleSubmit = async (files, parentFolderName) => {
    try {
      const withoutSlicing = true;

      if (files) {
        const archiveExt = ['zip', '7z', 'rar'];
        const fileExt = files.name.split('.').pop();
        const isArchive = archiveExt.includes(fileExt.toLowerCase());

        if (isArchive) {
          const reader = new zipjs.ZipReader(new zipjs.BlobReader(files));
          const originalZip = await reader.getEntries();

          validateFramesZip(originalZip, files.name);
          setFramesUploading(true);

          const zip = new zipjs.ZipWriter(
            new zipjs.BlobWriter('application/zip')
          );

          // // Add converted WebP files to the new zip
          for (const entry of originalZip) {
            const entryFile = await entry.getData(new zipjs.BlobWriter());

            if (!entry.filename.match(/\.(jpeg|jpg|png|webp)$/i)) continue;

            if (entry.filename.includes('.webp')) {
              // High quality
              const fileFullPath = entry.filename;
              const filename = fileFullPath.replace(/.*\//, '');
              await zip.add(
                `${parentFolderName}/high/${filename}`,
                new zipjs.BlobReader(entryFile)
              );

              // Low Quality
              const lowQualityImage = await createLowQualityImage(entryFile);
              const lowQualityImgFileName = filename.replace(
                'frame',
                'frame_low'
              );
              const webpLowQualityBlob = new File(
                [lowQualityImage],
                lowQualityImgFileName,
                {
                  type: 'image/webp',
                }
              );

              await zip.add(
                `${parentFolderName}/low/${lowQualityImgFileName}`,
                new zipjs.BlobReader(webpLowQualityBlob)
              );
            } else {
              // High quality
              const webpFileName =
                entry.filename.replace(/\.[^/.]+$/, '') + '.webp';
              const filename = webpFileName.replace(/.*\//, '');

              const blob = await convertToWebP(entryFile);
              const webpBlob = new File([blob], filename, {
                type: 'image/webp',
              });

              await zip.add(
                `${parentFolderName}/high/${filename}`,
                new zipjs.BlobReader(webpBlob)
              );

              // Low Quality
              const lowQualityImage = await createLowQualityImage(entryFile);
              const lowQualityImgFileName = filename.replace(
                'frame',
                'frame_low'
              );
              const webpLowQualityBlob = new File(
                [lowQualityImage],
                lowQualityImgFileName,
                {
                  type: 'image/webp',
                }
              );

              await zip.add(
                `${parentFolderName}/low/${lowQualityImgFileName}`,
                new zipjs.BlobReader(webpLowQualityBlob)
              );
            }
          }
          const finalZipBlob = await zip.close();
          const zipFile = new File([finalZipBlob], `${parentFolderName}.zip`, {
            type: 'application/zip',
          });

          const levelExist = levels.find(
            level => level.number === parseInt(parentFolderName)
          );
          if (levelExist) {
            await API.request(API.endpoints.DELETE_LEVEL, {
              locationId: id,
              level: parentFolderName,
            });
          }

          const tempLevels = [...levels];

          const newLevel = {
            number: parseInt(parentFolderName),
            customName: `Level ${parentFolderName}`,
            scale: 0,
            reverseRotation: false,
          };

          if (
            parentFolderName < (levels.length > 0 ? levels[0].number : 0) &&
            !levelExist
          ) {
            tempLevels.push(newLevel);
          } else if (
            parentFolderName >=
              (levels.length > 0 ? levels[levels.length - 1].number + 1 : 0) &&
            !levelExist
          ) {
            tempLevels.unshift(newLevel);
          }
          setLevels(tempLevels);

          onSubmit({
            files: zipFile,
            levels: tempLevels,
            withoutSlicing,
          });
        }
      }
    } catch (e) {
      setFramesUploading(false);
      dispatch(createToast('ERROR', e.message, e.heading));
    }
  };

  /**
   * Fetches file names from the server based on the selected level and updates the state.
   */
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/getFrameFileNamesFromLocation?locationId=${id}&level=${selectedLevel}`
    )
      .then(r => r.json())
      .then(r => r.result)
      .then(setEquiFileNamesServerContains);
  }, [uploaded, framesUploaded]);

  const alert = !uploaded && (
    <AlertTooltipIcon tooltip="The frames has not been uploaded" />
  );

  const info = (
    <InfoTooltipIcon
      tooltip={
        'After selecting the file, select number of floor and click "Upload". Zip archive should include one folder with files named: "frame_1", "frame_2", etc.'
      }
    />
  );

  const infoLevels = (
    <InfoTooltipIcon
      tooltip={
        'To exchange frames for one of the levels select level you need and upload frames one more time. Level 0 can`t be deleted.'
      }
    />
  );

  /**
   * Handles deleting a level by calling the API and updating the state.
   *
   * @param {number} levelId - Index of the level to delete.
   */
  const deleteLevelsHandler = async levelId => {
    try {
      await API.request(API.endpoints.DELETE_LEVEL, {
        locationId: id,
        level: levelId,
      });
    } catch (error) {
      console.log('error during deletion of level', error.message);
    }
    const tempLevels = levels.filter(
      (level, index) => level.number !== levelId
    );
    updateLevelsData({ levelsData: tempLevels, isDelete: true });
  };

  /**
   * Handles changes to the custom name of a level by updating the state.
   *
   * @param {Event} event - The change event.
   * @param {number} levelIndex - Index of the level to update.
   */
  const handleCustomNameChange = (event, levelIndex) => {
    const newLevels = [...levels];
    newLevels[levelIndex].customName = event.target.value;
    setLevels(newLevels);
  };

  /**
   * Handles changes to the scale of a level by updating the state.
   *
   * @param {Event} event - The change event.
   * @param {number} levelIndex - Index of the level to update.
   */
  const handleScaleChange = (event, levelIndex) => {
    const newLevels = [...levels];
    newLevels[levelIndex].scale = parseInt(event.target.value);
    setLevels(newLevels);
  };

  /**
   * Handles changes to the reverse rotation of a level by updating the state.
   *
   * @param {Event} event - The change event.
   * @param {number} levelIndex - Index of the level to update.
   */
  const handleReverseRotationChange = (event, levelIndex) => {
    const newLevels = [...levels];
    newLevels[levelIndex].reverseRotation = event.currentTarget.checked;
    setLevels(newLevels);
  };

  return (
    <>
      <Row>
        <Col>
          <FileInput
            label={
              <h5>
                Frames uploading <span className="text-muted">(.zip)</span>{' '}
                {info} {alert}
              </h5>
            }
            accept={'.zip'}
            onSubmit={async (files, selectedLevel) => {
              await handleSubmit(files, selectedLevel);
            }}
            onFileSelect={onFileSelect}
            submitButtonLabel={
              framesUploaded
                ? 'Frames uploaded'
                : uploading
                  ? 'Uploading...'
                  : 'Upload'
            }
            submitButtonDisabled={uploading || !allowUploading}
            errorText={error}
            disableInput={uploading}
            isInteractiveFloorPlan={true}
            levels={levels}
            uploading={uploading}
            selectedLevel={selectedLevel}
            setSelectedLevel={setSelectedLevel}
          />
        </Col>
      </Row>
      {levels && levels.length > 0 && (
        <>
          <Row style={{ marginTop: '20px', marginBottom: '8px' }}>
            <Col>
              <h5>Levels {infoLevels}</h5>
            </Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
          </Row>
          <Row style={{ marginTop: '10px', marginBottom: '8px' }}>
            <Col>Level</Col>
            <Col>Custom name</Col>
            <Col>Images scale in %</Col>
            <Col>Reverse rotation</Col>
            <Col></Col>
          </Row>
          {levels.map((level, index) => {
            return (
              <Row key={index} style={{ marginTop: '10px' }}>
                <Col>
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      marginLeft: '3px',
                    }}
                  >
                    {level.number}
                  </span>
                </Col>
                <Col>
                  <StyledInput
                    type="text"
                    value={level.customName}
                    onChange={e => handleCustomNameChange(e, index)}
                    maxLength={18}
                  />
                </Col>
                <Col>
                  <StyledInput
                    type="number"
                    value={level.scale}
                    onChange={e => handleScaleChange(e, index)}
                    maxLength={18}
                    min={-99}
                  />
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <StyledCheckbox
                    type="checkbox"
                    defaultChecked={level.reverseRotation}
                    onChange={e => handleReverseRotationChange(e, index)}
                  />
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={() => deleteLevelsHandler(level.number)}
                    size="sm"
                    disabled={
                      (index !== 0 && index !== levels.length - 1) ||
                      level.number === 0
                    }
                    style={{
                      minWidth: '40px',
                      backgroundColor: '#dc3545',
                      borderColor: '#dc3545',
                    }}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            );
          })}
        </>
      )}
    </>
  );
};

FrameZipInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  allowUploading: PropTypes.bool,
  uploading: PropTypes.bool,
  uploaded: PropTypes.bool,
  error: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.number,
};

FrameZipInput.defaultProps = {
  allowUploading: false,
  uploading: false,
  tilesUploaded: false,
  error: '',
};

export default connect(state => state)(FrameZipInput);
