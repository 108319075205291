import { useContext } from 'react';
import { ReactReduxContext } from 'react-redux';
import { useHistory } from 'react-router-dom';

import API from '../../../../shared/utils/API';
import {
  createToast,
  hidePopup,
  loadCompanies,
  showPopup,
} from '../../../../redux/actions';
import { nameRegex } from '../../../AddLocation/components/validate';

export const useDeleteCompany = loadCompanies => {
  const { store } = useContext(ReactReduxContext);
  const dispatch = store.dispatch;

  return id =>
    dispatch(
      showPopup({
        onSubmit: async () => {
          try {
            await API.request(API.endpoints.DELETE_COMPANY, { companyId: id });
            dispatch(
              createToast('SUCCESS', 'Company has been deleted successfully')
            );
            loadCompanies();
          } catch (e) {
            dispatch(createToast('ERROR', e.toString()));
            console.error(e);
          } finally {
            dispatch(hidePopup());
          }
        },
        label: {
          text: 'Attention! When deleting a company, all locations and properties that were created in it are deleted',
          color: 'red',
        },
      })
    );
};

export const useCreateCompany = () => {
  const { store } = useContext(ReactReduxContext);
  const dispatch = store.dispatch;
  const history = useHistory();

  return () =>
    dispatch(
      showPopup({
        onSubmit: async values => {
          try {
            await API.request(API.endpoints.CREATE_COMPANY, {
              companyName: values.companyName,
            });
            dispatch(
              createToast(
                'SUCCESS',
                'The company has been created successfully'
              )
            );
            dispatch(loadCompanies());
            history.push(`/${encodeURIComponent(values.companyName)}`);
          } catch (e) {
            dispatch(createToast('ERROR', e.toString()));
            console.error(e);
          } finally {
            dispatch(hidePopup());
          }
        },
        label: {
          text: 'Please enter company name',
          color: 'black',
        },
        submitButton: {
          text: 'Create',
          variant: 'success',
        },
        enableReinitialize: true,
        initialValues: {
          companyName: '',
        },
        validate: data => {
          const errors = {};
          if (nameRegex.test(data.companyName)) {
            errors.companyName = 'Field contains forbidden characters';
          }
          if (!data.companyName.trim()) {
            errors.companyName = 'Required field';
          }

          return errors;
        },
        formData: [
          {
            type: 'input',
            name: 'companyName',
            props: {
              placeholder: 'Company name',
              type: 'text',
              onChangeValidate: true,
            },
          },
        ],
      })
    );
};

export const useRenameCompany = () => {
  const { store } = useContext(ReactReduxContext);
  const dispatch = store.dispatch;

  return company =>
    dispatch(
      showPopup({
        onSubmit: async values => {
          try {
            const data = {
              id: company.id,
              data: {
                name: values.name,
              },
            };
            await API.request(API.endpoints.UPDATE_COMPANY, data);
            dispatch(
              createToast('SUCCESS', 'Company has been renamed successfully')
            );
            dispatch(loadCompanies());
          } catch (e) {
            dispatch(createToast('ERROR', e.toString()));
            console.error(e);
          } finally {
            dispatch(hidePopup());
          }
        },
        label: {
          text: 'Please enter new name',
          color: 'black',
        },
        submitButton: {
          text: 'Rename',
          variant: 'success',
        },
        enableReinitialize: true,
        initialValues: {
          name: company.name ? company.name : '',
        },
        validate: data => {
          const errors = {};
          if (nameRegex.test(data.name)) {
            errors.name = 'Field contains forbidden characters';
          }
          if (!data.name.trim()) {
            errors.name = 'Required field';
          }

          return errors;
        },
        formData: [
          {
            type: 'input',
            name: 'name',
            props: {
              placeholder: 'Company name',
              type: 'text',
              onChangeValidate: true,
            },
          },
        ],
      })
    );
};
