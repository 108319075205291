import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Collapse } from 'react-bootstrap';
import { Field, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import renderInlineField from './InlineField';

function PointFieldsDefault({
  fields,
  show,
  dispatch,
  INSTANT_PANO_LOCATION_POINT_SHORT_INFO,
}) {
  const toggleCheckboxes = e => {
    if (INSTANT_PANO_LOCATION_POINT_SHORT_INFO.some(p => p.noImage)) {
      dispatch(
        change(
          'update_location',
          'INSTANT_PANO_LOCATION_POINT_SHORT_INFO',
          INSTANT_PANO_LOCATION_POINT_SHORT_INFO.map(f => ({
            ...f,
            noImage: false,
          }))
        )
      );
    } else {
      dispatch(
        change(
          'update_location',
          'INSTANT_PANO_LOCATION_POINT_SHORT_INFO',
          INSTANT_PANO_LOCATION_POINT_SHORT_INFO.map(f => ({
            ...f,
            noImage: true,
          }))
        )
      );
    }
  };

  const clearPortal = idx => {
    dispatch(
      change(
        'update_location',
        `INSTANT_PANO_LOCATION_POINT_SHORT_INFO[${idx}]`,
        {
          ...INSTANT_PANO_LOCATION_POINT_SHORT_INFO[idx],
          portalId: '',
          portalPoint: '',
        }
      )
    );
  };

  const clearHotspotId = idx => {
    dispatch(
      change(
        'update_location',
        `INSTANT_PANO_LOCATION_POINT_SHORT_INFO[${idx}]`,
        { ...INSTANT_PANO_LOCATION_POINT_SHORT_INFO[idx], hotspotId: '' }
      )
    );
  };

  return (
    <Collapse in={show}>
      <div
        className="mt-4 mb-4 pt-4 pb-3"
        style={{ backgroundColor: '#f4f4f4' }}
      >
        <Col>
          <Row className="pb-2">
            <Col className="pl-4" sm={1}>
              Point
            </Col>
            <Col className="pl-4" sm={1}>
              X
            </Col>
            <Col className="pl-4" sm={1}>
              Y
            </Col>
          </Row>
        </Col>

        {fields.map((point, index) => {
          const info = fields.get(index);

          return (
            <Col
              key={index}
              className="pt-2 pb-2"
              style={{
                backgroundColor: info && info.noImage ? '#ffdee2' : '#f4f4f4',
              }}
            >
              <Form.Group as={Row} className="m-0">
                <Form.Label column sm={1}>
                  <b>{`${index + 1}`}</b>
                </Form.Label>
                <Field
                  name={`${point}.x`}
                  type="text"
                  placeholder="X"
                  component={renderInlineField}
                  span={1}
                />
                <Field
                  name={`${point}.y`}
                  type="text"
                  placeholder="Y"
                  component={renderInlineField}
                  span={1}
                />
              </Form.Group>
            </Col>
          );
        })}
      </div>
    </Collapse>
  );
}

const selector = formValueSelector('update_location');

PointFieldsDefault.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => {
  return {
    INSTANT_PANO_LOCATION_POINT_SHORT_INFO: selector(
      state,
      'INSTANT_PANO_LOCATION_POINT_SHORT_INFO'
    ),
  };
})(PointFieldsDefault);
