import React from 'react';
import { connect } from 'react-redux';
import Header from './components/Header';
import Toasts from '../Toasts/Toasts';
import { hidePopup, setSearchName } from '../../redux/actions';
import Popup from '../../shared/components/Popup';

const Layout = ({
  auth,
  setSearchName,
  searchName,
  location,
  popup,
  hidePopup,
  children,
}) => {
  return (
    <>
      <Toasts />
      <Popup {...popup} hidePopup={hidePopup} />
      <Header
        auth={auth}
        setSearchName={setSearchName}
        searchName={searchName}
        location={location}
      />
      {children}
    </>
  );
};

const mapDispatchToProps = {
  setSearchName,
  hidePopup,
};

const mapStateToProps = state => ({
  auth: state.auth,
  searchName: state.searchName,
  location: state.location,
  popup: state.popup,
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
