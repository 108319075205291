import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from 'react-bootstrap';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import HotspotModal from './components/HotspotModal';
import Popup from '../../shared/components/Popup';
import API from '../../shared/utils/API';
import { HotspotTypes } from '../../constants';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import {
  createToast,
  loadCompanies,
  loadHotspotList,
} from '../../redux/actions';

const HotspotTypeLabels = {
  image: 'Image',
  gallery: 'Gallery',
  text: 'Text',
  iframe: 'Frame',
  furniture: 'Furniture',
};

function Hotspot({
  hotspotList,
  companyList,
  auth,
  loadHotspotList,
  loadCompanies,
  createToast,
}) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selected, setSelected] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [sortAscending, setSortAscending] = useState(true);
  const [hotspotListOrdered, setHotspotListOrdered] = useState([]);
  const [hotspotKeywords, setHotspotKeywords] = useState([]);
  const [filteredHotspots, setFilteredHotspots] = useState(null);
  const resetSelected = () => setSelected(null);

  if (!auth.authenticated) {
    return <Redirect to="/" />;
  }

  useEffect(() => {
    loadHotspotList();
    loadCompanies();
  }, []);

  useEffect(() => {
    if (hotspotList) {
      setHotspotListOrdered(hotspotList);
    }

    if (hotspotList && companyList && hotspotKeywords.length === 0) {
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api`;

      fetch(`${endpoint}/getAllLocations`)
        .then(response => response.json())
        .then(response => {
          if (response.result.length) {
            const locations = response.result;
            const keywords = [];

            for (const hotspot of hotspotList) {
              const location = locations.find(
                loc => loc.id === hotspot.location_id
              );
              const hotspotKeywords = {
                id: hotspot.id,
                list: [hotspot.id.toString(), hotspot.title.toString()],
              };
              keywords.push(hotspotKeywords);

              if (!location) {
                continue;
              }

              const companyId = location.company_id;

              if (companyId === undefined) {
                continue;
              }

              const company = companyList.value.find(
                company => company.id === companyId
              );

              if (!company) {
                continue;
              }

              const companyName = company.name;
              hotspotKeywords.list.push(companyName.toString());
            }

            setHotspotKeywords(keywords);
          }
        });
    }
  }, [hotspotList, companyList, hotspotKeywords]);

  const reverseHotspots = newOrder => {
    setHotspotListOrdered(hotspotListOrdered.reverse());
    setSortAscending(newOrder);
  };

  const updateSearch = query => {
    query = query.trim();

    if (!hotspotKeywords || !query) {
      setFilteredHotspots(null);

      return;
    }

    const matchingHotspots = [];

    hotspotKeywords.forEach(({ list, id }) => {
      for (const str of list) {
        if (str.toLocaleLowerCase().search(query.toLocaleLowerCase()) > -1) {
          matchingHotspots.push(id);
          break;
        }
      }
    });

    setFilteredHotspots(matchingHotspots);
  };

  const addHandler = async data => {
    setUploading(true);
    try {
      if (data) {
        const fd = new FormData();
        fd.append('type', data.type);
        fd.append('title', data.title);
        if (data.type !== HotspotTypes.GALLERY) {
          fd.append('main_text', data.main_text);
        }
        if (data.type === HotspotTypes.IFRAME) {
          fd.append('iframe_link', data.iframe_link);
          fd.append('full_screen_mode', data.full_screen_mode);
        }
        if (
          data.type === HotspotTypes.IMAGE ||
          data.type === HotspotTypes.FURNITURE
        ) {
          fd.append('images', data.image[0]);
          fd.append('button_text', data.button_text);
          fd.append('link', data.link);
        }
        if (data.type === HotspotTypes.GALLERY) {
          data.items.forEach(item => fd.append('items', item));
        }
        await API.request(API.endpoints.ADD_HOTSPOT, fd);
        console.log('data', data);
        console.log('fd', fd);
        setShowAddModal(false);
        setUploading(false);
        resetSelected();
        createToast('SUCCESS', 'Hotspot has been created');
        loadHotspotList();
      } else {
        throw new Error(`addHandler: data is ${data}`);
      }
    } catch (error) {
      console.error(error);
      setUploading(false);
      createToast('ERROR', error.toString());
    }
  };

  const editHandler = async data => {
    setUploading(true);
    try {
      if (data) {
        const fd = new FormData();
        fd.append('hotspotId', data.id);
        fd.append('type', data.type);
        fd.append('title', data.title);
        if (
          data.type === HotspotTypes.TEXT ||
          data.type === HotspotTypes.IMAGE
        ) {
          fd.append('subtitle', data.subtitle);
        }
        if (data.type !== HotspotTypes.GALLERY) {
          fd.append('main_text', data.main_text);
        }
        if (data.type === HotspotTypes.IFRAME) {
          fd.append('iframe_link', data.iframe_link);
          fd.append('full_screen_mode', data.full_screen_mode);
        }
        if (
          data.type === HotspotTypes.IMAGE ||
          data.type === HotspotTypes.FURNITURE
        ) {
          fd.append('images', data.image[0]);
          fd.append('button_text', data.button_text);
          fd.append('link', data.link);
        }
        if (data.type === HotspotTypes.GALLERY) {
          data.items.forEach(item => fd.append('items', item));
        }
        console.log(
          'hotspotId = ' + data.id,
          'title = ' + data.title,
          'subtitle = ' + data.subtitle,
          'main_text = ' + data.main_text,
          'button_text = ' + data.button_text,
          'link = ' + data.link,
          'iframe_link = ' + data.iframe_link,
          'type = ' + data.type
        );
        await API.request(API.endpoints.UPDATE_HOTSPOT, fd);
        setShowEditModal(false);
        setUploading(false);
        resetSelected();
        createToast('SUCCESS', 'Hotspot has been updated');
        loadHotspotList();
      } else {
        throw new Error(`editHandler: data is ${data}`);
      }
    } catch (error) {
      console.error(error);
      setUploading(false);
      createToast('ERROR', error.toString());
    }
  };

  const deleteHandler = async () => {
    try {
      if (selected) {
        await API.request(API.endpoints.DELETE_HOTSPOT, {
          hotspotId: selected.id,
        });
        setShowDeletePopup(false);
        resetSelected();
        createToast('SUCCESS', 'Hotspot has been deleted');
        loadHotspotList();
      } else {
        throw new Error(`deleteHandler: selected is ${selected}`);
      }
    } catch (error) {
      console.error(error);
      createToast('ERROR', error.toString());
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <h1 className="mt-3 mb-3">List of all hotspots</h1>
          </Col>
          <Col xs="auto">
            <Row className={'mt-3 mb-3'}>
              <Form.Control
                placeholder="Hotspot search"
                type="text"
                style={{
                  height: 32,
                  width: 250,
                }}
                defaultValue={''}
                onChange={e => {
                  updateSearch(e.target.value);
                }}
              />
              <MagnifyIcon
                style={{
                  height: 30,
                  color: 'black',
                  margin: 4,
                }}
              />
            </Row>
          </Col>
        </Row>
        <ListGroup variant="flush">
          <ListGroup.Item style={{ outline: 0 }}>
            <Row>
              <Col></Col>
              <Col xs="auto">
                <Button
                  variant="success"
                  onClick={() => reverseHotspots(!sortAscending)}
                >
                  {sortAscending ? (
                    <SortDescendingIcon />
                  ) : (
                    <SortAscendingIcon />
                  )}
                </Button>
              </Col>
              {/* <Col xs='auto'>
                <Button variant='success' onClick={() => setShowAddModal(true)}>
                  <AddIcon />
                </Button>
              </Col> */}
            </Row>
          </ListGroup.Item>
          {hotspotList.map(item => {
            if (
              filteredHotspots !== null &&
              !filteredHotspots.includes(item.id)
            ) {
              return;
            }

            return (
              <ListGroup.Item style={{ outline: 0 }} key={item.id}>
                <Row>
                  <Col xs="auto">
                    <div>{item.id}</div>
                  </Col>
                  <Col>
                    <div>{item.title}</div>
                  </Col>
                  <Col xs="auto">
                    <Badge pill variant="dark" className="mr-2">
                      {HotspotTypeLabels[item.type]}
                    </Badge>
                    <Button
                      className="mr-2"
                      onClick={() => {
                        setSelected(item);
                        setShowEditModal(true);
                      }}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        setSelected(item);
                        setShowDeletePopup(true);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Container>
      <HotspotModal
        show={showAddModal}
        uploading={uploading}
        onSubmit={addHandler}
        onClose={() => setShowAddModal(false)}
      />
      <HotspotModal
        show={showEditModal}
        edit
        hotspot={selected}
        uploading={uploading}
        onSubmit={editHandler}
        onClose={() => setShowEditModal(false)}
      />
      <Popup
        show={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        onSubmit={deleteHandler}
        label={{
          text: 'Do you really want to delete this hotspot?',
          color: 'black',
        }}
      />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  hotspotList: state.hotspotList,
  companyList: state.companyList,
  settings: state.settings,
  auth: state.auth,
});

const mapDispatchToProps = {
  loadHotspotList,
  loadCompanies,
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Hotspot);
