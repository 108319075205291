import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as zipjs from '@zip.js/zip.js';

import { debug } from './debugUtils';
import generateGLTF from './generateGLTF';
import { ZipFileInputStorage } from '../../containers/LocationSettings/components/ZipFileInput';
import { validateArchive3d } from '../utils/validate';

export default async function parseModelGenerator(
  locationId,
  locationDescription,
  noPanos,
  updateHandler
) {
  const progressbar = {
    totalFiles: 0,
    filesLoaded: 0,
    getProgress: () =>
      ~~((progressbar.filesLoaded / progressbar.totalFiles) * 100),
  };

  return new Promise(async (resolve, reject) => {
    try {
      const gltfUrl = `${process.env.REACT_APP_MEDIA_URL}/locations/${locationId}/model/location.gltf`;
      const binUrl = `${process.env.REACT_APP_MEDIA_URL}/locations/${locationId}/model/location.bin`;
      const mediaUrl = process.env.REACT_APP_MEDIA_URL;
      const numberOfUrls = 10;

      const equiUrls = Array.from(
        { length: numberOfUrls },
        (_, index) =>
          `${process.env.REACT_APP_MEDIA_URL}/locations/${locationId}/model/equi/eq_${index + 1}.jpg`
      );

      debug(() => console.groupCollapsed('ZIP file/GLTF parsing'));
      const loader = new GLTFLoader();
      const file_bin = 'location.bin';
      const file_gltf = 'location.gltf';
      const url = '/';
      THREE.Cache.enabled = true;
      const dateBefore = Date.now();

      const eqImages = equiUrls.map(url => {
        const filename = url.split('/').pop(); // Получаем имя файла из URL
        const numberPart = filename.replace('eq_', '').replace('.jpg', ''); // Извлекаем числовую часть из имени файла

        return Number(numberPart); // Преобразуем к числу
      });

      const cubeImages = [];

      // progressbar.totalFiles = equiTextures.length + filesList.length + 1; // 1 for GLTF file

      const proc_bin = async () => {
        const res = await fetch(binUrl);
        console.log(res);
        const blob = await res.blob();
        console.log(blob);
        const data = await blob.arrayBuffer();
        THREE.Cache.add(`${url}/${file_bin}`, data);
        console.log('Файл "location.bin" добавлен в кеш.');
      };
      await proc_bin();
      const removeTextureLinks = gltf => {
        try {
          gltf = JSON.parse(gltf);

          if (gltf.materials) {
            for (const material of gltf.materials) {
              material.pbrMetallicRoughness.baseColorTexture = undefined;
            }
          }

          return JSON.stringify(gltf);
        } catch (e) {
          throw new Error(
            'Mesh error: unable to process location.gltf, please check your location model'
          );
        }
      };

      const proc_gltf = async () => {
        let gltfDataResponse = await fetch(gltfUrl);
        if (gltfDataResponse.redirected) {
          console.log(gltfDataResponse.url);
          gltfDataResponse = await fetch(gltfDataResponse.url);
        }
        const gltfData = await gltfDataResponse.text();

        // mesh path fix
        const gltfDataFixed = gltfData.split('uri":"./').join('uri":"');
        const gltfNoTextures = removeTextureLinks(gltfDataFixed);
        THREE.Cache.add(`${url}/${file_gltf}`, gltfNoTextures);
      };
      // progressbar.filesLoaded++;
      await proc_gltf();
      // for (const equiUrl of equiUrls) {
      //   let imageResponse = await fetch(equiUrl);
      //   console.log(imageResponse);
      //
      //   if (imageResponse.redirected) {
      //     // const redirectUrl = imageResponse.headers.get('location');
      //     imageResponse =  await  fetch(imageResponse.url);
      //   }
      //   const imageData = await imageResponse.arrayBuffer();
      //   // const imageData = await imageResponse.arrayBuffer();
      //   console.log(imageData);
      //   // Replace 'equi/eq_' with 'pan' to match the cache key in the original code
      //   const panKey = equiUrl.replace('equi/eq_', 'pan');
      //   console.log(panKey);
      //
      //   THREE.Cache.add(panKey, imageData);
      //
      //   progressbar.filesLoaded++;
      // }

      // Unzip other files
      // for (const i in filesList) {
      //   const item = filesList[i];
      //
      //   const data = await unzipAsArrayBuffer(zip, item, (index, total) =>
      //     updateHandler(item, progressbar.getProgress()),
      //   );
      //
      //   THREE.Cache.add(`${url}/${item}`, data);
      //
      //   progressbar.filesLoaded++;
      // }
      // debug(() => console.info('Cache:'));
      // Object.keys(THREE.Cache.files).forEach(key => console.log(key));

      const loadAsync = () => {
        return new Promise((rs, rj) => {
          loader.load(
            `${url}/${file_gltf}`,
            async gltf => {
              const points = [];
              const furniture = [];
              gltf.scene.children.forEach(child => {
                const pointMatch = child.name.match(/Sphere(\d{1,5})/);
                const furnitureMatch = child.name.match(/Selectable_(\d{1,5})/);
                if (pointMatch) {
                  points[Number(pointMatch[1]) - 1] = {
                    position: child.position,
                    // noImage:
                    //   noPanos ? false : (
                    //     !eqImages.includes(Number(pointMatch[1])) &&
                    //     !cubeImages.includes(Number(pointMatch[1]))
                    //   ),
                  };
                } else if (furnitureMatch) {
                  furniture.push([Number(furnitureMatch[1])]);
                }
              });
              console.info(`${points.length} points`);
              console.info(`${furniture.length} furniture items`);
              console.info(`processed in ${Date.now() - dateBefore}ms`);
              debug(() => console.groupEnd());

              const minimaps = locationDescription.MAP_IMAGES || {};
              let aspectRatio = 1;

              if (Object.values(minimaps).length > 0) {
                const url =
                  process.env.REACT_APP_MEDIA_URL +
                  '/' +
                  Object.values(minimaps)[0];
                const { width, height } = await getImageResolution(url);

                aspectRatio = width / height;
              }

              const minimapPoints = getMinimapPoints(gltf, points, 1);

              rs({ points, minimapPoints, furniture });
            },
            () => {},
            err => {
              rj({
                message:
                  'Mesh error: unable to process location.gltf, please check your location model',
              });
            }
          );
        });
      };
      const res = await loadAsync();
      console.log(res);
      resolve(res);
    } catch (error) {
      debug(() => console.groupEnd());
      reject(error);
    }
  });
}

function getMinimapPoints(gltf, points, cameraAspect) {
  if (gltf.cameras.length < 1) {
    return null;
  }

  const camera = gltf.cameras[0];
  const result = [];

  camera.aspect = cameraAspect;

  camera.updateProjectionMatrix();

  if (camera.parent && camera.parent instanceof THREE.Object3D) {
    camera.parent.updateMatrix();
    camera.parent.updateMatrixWorld();
  }

  camera.updateMatrixWorld();

  for (const { position } of points) {
    const p = position.clone();

    p.project(camera);

    result.push(p);
  }

  return result;
}

export async function getImageResolution(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      resolve({ width: image.width, height: image.height });
    };

    image.onerror = () => {
      resolve({ width: 1024, height: 1024 });
    };
  });
}
