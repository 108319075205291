import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Button, ListGroup } from 'react-bootstrap';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import AddIcon from 'mdi-react/AddIcon';

import { loadFurnitureList, createToast } from '../../redux/actions';
import FurnitureSettings from './components/FurnitureModal';
import Popup from '../../shared/components/Popup';
import API from '../../shared/utils/API';

function Furniture({ furnitureList, auth, loadFurnitureList, createToast }) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selected, setSelected] = useState(null);
  const [uploading, setUploading] = useState(false);
  const resetSelected = () => setSelected(null);

  if (!auth.authenticated) {
    return <Redirect to="/" />;
  }

  useEffect(() => {
    loadFurnitureList();
  }, []);

  const addHandler = async data => {
    setUploading(true);
    try {
      if (data) {
        const fd = new FormData();
        fd.append('name', data.name);
        fd.append('link', data.link);
        fd.append('price', data.price);
        fd.append('label', data.label);
        fd.append('images', data.image[0]);
        await API.request(API.endpoints.ADD_FURNITURE, fd);
        setShowAddModal(false);
        setUploading(false);
        resetSelected();
        createToast('SUCCESS', 'Furniture has been created');
        loadFurnitureList();
      } else {
        throw new Error(`addHandler: data is ${data}`);
      }
    } catch (error) {
      console.error(error);
      setUploading(false);
      createToast('ERROR', error.toString());
    }
  };

  const editHandler = async data => {
    setUploading(true);
    try {
      if (data) {
        const fd = new FormData();
        fd.append('furnitureId', data.id);
        fd.append('name', data.name);
        fd.append('link', data.link);
        fd.append('price', data.price);
        fd.append('label', data.label);
        fd.append('images', data.image[0]);
        await API.request(API.endpoints.UPDATE_FURNITURE, fd);
        setShowEditModal(false);
        setUploading(false);
        resetSelected();
        createToast('SUCCESS', 'Furniture has been updated');
        loadFurnitureList();
      } else {
        throw new Error(`editHandler: data is ${data}`);
      }
    } catch (error) {
      console.error(error);
      setUploading(false);
      createToast('ERROR', error.toString());
    }
  };

  const deleteHandler = async () => {
    try {
      if (selected) {
        await API.request(API.endpoints.DELETE_FURNITURE, {
          furnitureId: selected.id,
        });
        setShowDeletePopup(false);
        resetSelected();
        createToast('SUCCESS', 'Furniture has been deleted');
        loadFurnitureList();
      } else {
        throw new Error(`deleteHandler: selected is ${selected}`);
      }
    } catch (error) {
      console.error(error);
      createToast('ERROR', error.toString());
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <h1 className="mt-3 mb-3">Furniture</h1>
          </Col>
        </Row>
        <ListGroup variant="flush">
          <ListGroup.Item style={{ outline: 0 }}>
            <Row>
              <Col></Col>
              <Col xs="auto">
                <Button variant="success" onClick={() => setShowAddModal(true)}>
                  <AddIcon />
                </Button>
              </Col>
            </Row>
          </ListGroup.Item>
          {furnitureList.map(item => (
            <ListGroup.Item style={{ outline: 0 }} key={item.id}>
              <Row>
                <Col xs="auto">
                  <div>{item.id}</div>
                </Col>
                <Col>
                  <div>{item.name}</div>
                </Col>
                <Col xs="auto">
                  <Button
                    className="mr-2"
                    onClick={() => {
                      setSelected(item);
                      setShowEditModal(true);
                    }}
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      setSelected(item);
                      setShowDeletePopup(true);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Container>
      <FurnitureSettings
        show={showAddModal}
        uploading={uploading}
        onSubmit={addHandler}
        onClose={() => setShowAddModal(false)}
      />
      <FurnitureSettings
        show={showEditModal}
        edit
        furniture={selected}
        uploading={uploading}
        onSubmit={editHandler}
        onClose={() => setShowEditModal(false)}
      />
      <Popup
        show={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        onSubmit={deleteHandler}
      />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  furnitureList: state.furnitureList,
  settings: state.settings,
  auth: state.auth,
});

const mapDispatchToProps = {
  loadFurnitureList,
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Furniture);
