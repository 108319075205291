import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Form, Collapse, Spinner } from 'react-bootstrap';
import { Field } from 'redux-form';

import { HotspotNames, HotspotTypes } from '../../../constants';
import renderInlineField from './InlineField';

function FurnitureFieldsItem({ item, options, furniture, locationId }) {
  const [hotspotType, setHotspotType] = useState('');
  // Ref to input is needed to get the loaded hotspot type (it's kind of a crutch)
  const typeInputRef = useRef(null);

  useEffect(() => {
    if (typeInputRef.current && typeInputRef.current.value) {
      setHotspotType(typeInputRef.current.value);
    }
  }, []);

  const hotspotOptions = options
    .filter(hotspot => hotspot.type === hotspotType)
    .filter(
      hotspot =>
        hotspot.location_id === +locationId || hotspot.id == furniture[item]
    );

  return (
    <Col key={item} className="pt-2 pb-2">
      <Form.Group as={Row} className="m-0">
        <Form.Label column sm={2}>
          {`Subject #${item}`}
        </Form.Label>
        <Field
          name={`FURNITURE_TYPE.${item}`}
          component={renderInlineField}
          span={3}
          as="select"
          onChange={e => setHotspotType(e.target.value)}
          ref={typeInputRef}
        >
          <option value="">Not selected</option>
          {Object.values(HotspotTypes).map(hotspotType => (
            <option key={hotspotType} value={hotspotType}>
              {HotspotNames[hotspotType]}
            </option>
          ))}
        </Field>
        {!!hotspotType && hotspotType !== HotspotTypes.POINT && (
          <Field
            name={`FURNITURE.${item}`}
            component={renderInlineField}
            span={3}
            type="number"
            placeholder="Hotspot ID"
          />
        )}
        {!!hotspotType && hotspotType === HotspotTypes.POINT && (
          <>
            <Field
              name={`FURNITURE_PORTAL_IDS.${item}`}
              type="text"
              placeholder="Location ID"
              component={renderInlineField}
              span={2}
            />
            <Field
              name={`FURNITURE_PORTAL_POINTS.${item}`}
              type="text"
              placeholder="Start point"
              component={renderInlineField}
              span={2}
            />
          </>
        )}
        <Field
          type="color"
          name={`FURNITURE_COLORS.${item}`}
          label="Color"
          component={renderInlineField}
          span={2}
        />
      </Form.Group>
    </Col>
  );
}

export default function FurnitureFields({
  locationId,
  furniture,
  items,
  options,
  show,
}) {
  return (
    <Collapse in={show}>
      {!options.length ? (
        <div
          className="mt-4 mb-4 pt-4 pb-3"
          style={{ backgroundColor: '#f4f4f4' }}
        >
          <Spinner animation="border" role="status" className="ml-3">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div
          className="mt-4 mb-4 pt-4 pb-3"
          style={{ backgroundColor: '#f4f4f4' }}
        >
          {items.map(item => (
            <FurnitureFieldsItem
              key={item}
              item={item}
              locationId={locationId}
              furniture={furniture}
              options={options}
            />
          ))}
        </div>
      )}
    </Collapse>
  );
}
