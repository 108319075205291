import React, { useState, useEffect } from 'react';
import { reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import generateGLTF from '../../../shared/utils/generateGLTF';
import JSZip from 'jszip';
import { sendPartZip } from '../../../shared/utils/ZipBuilder';

export const buildAndSendGLTF = async (pointCount, callback) => {
  pointCount = parseInt(pointCount);

  if (isNaN(pointCount) || pointCount < 0) {
    callback();

    return;
  }

  const ids = Array.from(Array(pointCount).keys()).map(el => el + 1);
  const filesData = generateGLTF(undefined, ids);
  const zip = new JSZip();

  zip.file(filesData[0][0], filesData[0][1]);
  zip.file(filesData[1][0], filesData[1][1]);

  await sendPartZip(zip);

  callback();
};

const GLTFBuilderInput = ({
  initialValues,
  instantPanoOnly,
  onGltfUpdate,
  onPointCountChange,
  dispatch,
}) => {
  const [instantPanoPointsCount, setInstantPanoPointsCount] = useState(
    initialValues.INSTANT_PANO_POINTS_COUNT
  );
  const [gltfBuildLoading, setGltfBuildLoading] = useState(false);

  useEffect(() => {
    if (instantPanoOnly && instantPanoPointsCount !== null) {
      onPointCountChange(instantPanoPointsCount);
      // const points = onPointCountChange(instantPanoPointsCount);
      // dispatch(change('update_location', 'LOCATION_POINT_SHORT_INFO', points));
    }
  }, [instantPanoPointsCount, instantPanoOnly]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '20%',
          gap: '20px',
        }}
      >
        <input
          type="number"
          placeholder="Number of points"
          value={instantPanoPointsCount}
          onChange={event =>
            setInstantPanoPointsCount(parseInt(event.target.value))
          }
        />
        <Button
          onClick={() => {
            setGltfBuildLoading(true);
            buildAndSendGLTF(instantPanoPointsCount, () => {
              setGltfBuildLoading(false);
            });
            if (!isNaN(parseInt(instantPanoPointsCount))) {
              onGltfUpdate(Math.max(parseInt(instantPanoPointsCount), 0));
            }
          }}
          disabled={gltfBuildLoading}
        >
          {gltfBuildLoading
            ? 'Generation and sending of GLTF...'
            : 'Generate a model'}
        </Button>
      </div>
    </>
  );
};

// GLTFBuilderInput.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   uploading: PropTypes.bool,
// };

// FaviconFileInput.defaultProps = {
//   uploading: false,
// };

export default reduxForm({
  form: 'update_location',
  enableReinitialize: true,
})(GLTFBuilderInput);
