import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import API from '../../shared/utils/API';
import {
  createToast,
  loadCompanies,
  loadHotspotList,
} from '../../redux/actions';
import styled from 'styled-components';

const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api`;

async function getAllLocations() {
  return fetch(`${endpoint}/getAllLocations`).then(response => response.json());
}

async function getAllIcons() {
  return fetch(`${endpoint}/icons/all`).then(response => response.json());
}

const HoverableIconImage = styled.img`
  width: 128px;
  height: 128px;
  display: block;
`;

function HoverableIcon({ url, urlHover }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <HoverableIconImage
      src={isHovered ? urlHover : url}
      onPointerOver={() => setIsHovered(true)}
      onPointerOut={() => setIsHovered(false)}
    />
  );
}

const IconContainer = styled.div`
  margin: 0 8px 8px 0;
`;

const IconLabel = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  margin: 4px 0 8px 0;
`;

function Icon({ url, urlHover, id }) {
  return (
    <IconContainer>
      <HoverableIcon url={url} urlHover={urlHover} />
      <IconLabel>ID: {id}</IconLabel>
    </IconContainer>
  );
}

function Icons({ companyList, auth, loadCompanies, createToast }) {
  const [icons, setIcons] = useState({});

  if (!auth.authenticated) {
    return <Redirect to="/" />;
  }

  useEffect(() => {
    loadCompanies();
  }, []);

  useEffect(() => {
    if (companyList) {
      Promise.all([getAllLocations(), getAllIcons()]).then(
        ([locations, icons]) => {
          const map = {};

          for (const icon of icons.result) {
            const location = locations.result.find(
              location => location.id === icon.location_id
            );

            if (!location) {
              continue;
            }

            const company = companyList.value.find(
              company => company.id === location.company_id
            );

            if (!company) {
              continue;
            }

            const key = company.name;

            if (!map[key]) {
              map[key] = [];
            }

            const url = `${process.env.REACT_APP_MEDIA_URL}/icons/${icon.path}`;

            map[key].push([icon.id, url, url.replace('.png', '_hover.png')]);
          }

          setIcons(map);
        }
      );
    }
  }, [companyList]);

  const deleteHandler = async id => {
    try {
      await API.request(API.endpoints.DELETE_ICON, {
        iconId: id,
      });
      createToast('SUCCESS', 'Icon has been deleted');
      loadHotspotList();
    } catch (error) {
      console.error(error);
      createToast('ERROR', error.toString());
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <h1 className="mt-3 mb-3">List of all icons</h1>
          </Col>
        </Row>
        <ListGroup variant="flush">
          {Object.entries(icons).map(([companyName, iconURLs]) => {
            return (
              <ListGroup.Item key={companyName} style={{ outline: 0 }}>
                <Row>
                  <h3>{companyName}</h3>
                </Row>
                <Row>
                  {iconURLs.map(url => {
                    return (
                      <Icon
                        key={url[0]}
                        id={url[0]}
                        url={url[1]}
                        urlHover={url[2]}
                      />
                    );
                  })}
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  companyList: state.companyList,
  auth: state.auth,
});

const mapDispatchToProps = {
  loadCompanies,
  createToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Icons);
