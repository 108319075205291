import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';

import LoginForm from './components/LoginForm';
import { authenticate } from '../../../redux/actions';

const Login = ({ auth, dispatch }) => {
  const [error, setError] = useState(false);
  if (auth.authenticated) {
    return <Redirect to="/dashboard" />;
  }

  const handleSubmit = values => {
    if (
      values.email === 'admin@example.com' &&
      values.password === 'qwerty123'
    ) {
      const token = 'token123';
      const valid = new Date(Date.now() + 864000000);
      dispatch(authenticate({ token, valid }));
    } else {
      setError(true);
    }
  };

  return (
    <Container className="h-100">
      <Row className="h-100">
        <Col className="d-flex justify-content-center align-items-center">
          <LoginForm showError={error} onSubmit={handleSubmit} />
        </Col>
      </Row>
    </Container>
  );
};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

export default connect(state => ({
  auth: state.auth,
}))(Login);
