import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast, types } from '../types';

import { deleteToast } from '../../../redux/actions';

function Toast({ deleteToast, id, toasts }) {
  const [show, setShow] = useState(false);

  const toast = useMemo(() => {
    return toasts.find(toast => toast.id === id);
  }, [id, toasts]);

  const handleClose = useCallback(() => {
    setShow(false);
    deleteToast(toast.id);
  }, [toast.id]);

  useEffect(() => {
    const showTimeout = setTimeout(() => setShow(true), 1);

    return () => clearTimeout(showTimeout);
  }, []);

  useEffect(() => {
    // if (toast.type !== types.ERROR) {
    const deleteTimeout = setTimeout(() => handleClose(), 3000);

    return () => clearTimeout(deleteTimeout);
    // }
  }, []);

  const templates = useMemo(() => {
    return {
      [types.SUCCESS]: {
        heading: toast.heading || 'Success!',
        variant: 'success',
      },
      [types.ERROR]: {
        heading: toast.heading || 'Oops! Something went wrong.',
        variant: 'danger',
      },
    };
  }, []);

  const { heading, variant } = useMemo(
    () => templates[toast.type],
    [templates, toast.type]
  );

  return (
    <Alert dismissible onClose={handleClose} show={show} variant={variant}>
      <Alert.Heading>{heading}</Alert.Heading>
      <div style={{ wordBreak: 'break-word' }}>{toast.text}</div>
    </Alert>
  );
}

Toast.propTypes = {
  id: PropTypes.number.isRequired,
  toasts: PropTypes.arrayOf(toast).isRequired,
};

const mapStateToProps = state => ({ toasts: state.toasts });
const mapDispatchToProps = { deleteToast };

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
