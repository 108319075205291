import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import {
  loadLocationsOfCompany,
  resetCompanyLocations,
  showContextMenu,
} from '../../../../redux/actions';
import { connect } from 'react-redux';
import LocationItem from '../LocationItem';
import { Col, Row } from 'react-bootstrap';
import { GridContext } from '../../../../shared/utils/DragAndDrop/GridProvider';
import DragItem from '../../../../shared/utils/DragAndDrop/DragItem';
import API from '../../../../shared/utils/API';
import { useDragLayer } from 'react-dnd';
import PlusItemCard from '../PlusItemCard';
import EmptyPlus from '../EmptyPlus';
import { useCreateLocation } from './hooks';
import ContextMenu from '../ContextMenu';

function Multilocation({
  loadLocationsOfCompany,
  resetCompanyLocations,
  companyData,
  settings,
  searchName,
  showContextMenu,
}) {
  const { company, multilocation } = useParams();
  const companyDecoded = decodeURIComponent(company);
  const multilocationDecoded = decodeURIComponent(multilocation);

  const { items, moveItem, setItems } = useContext(GridContext);

  const [prevItems, setPrevItems] = useState([]);
  const createLocation = useCreateLocation(
    companyDecoded,
    multilocationDecoded
  );

  const collectedProps = useDragLayer(monitor => {
    return {
      isDragging: monitor.isDragging(),
    };
  });

  useEffect(() => {
    loadLocationsOfCompany(companyDecoded);

    return () => {
      resetCompanyLocations();
    };
  }, []);

  const updateItems = () => {
    if (!companyData || !companyData.multilocations) {
      return;
    }

    const multilocationData = companyData.multilocations.find(
      m => m.name === multilocationDecoded
    );

    if (!multilocationData) {
      return;
    }

    multilocationData.locations.sort(
      (a, b) =>
        a.description.LOCATION_PRIORITY - b.description.LOCATION_PRIORITY
    );

    setItems(multilocationData.locations);
    setPrevItems([...multilocationData.locations]);
  };

  useEffect(() => {
    updateItems();
  }, [companyData]);

  useEffect(() => {
    async function sendChanges() {
      const tasks = items.map((item, index) => {
        const description = {
          ...item.description,
          LOCATION_PRIORITY: index + 1,
        };
        const data = {
          locationId: item.id,
          name: item.name,
          companyName: item.company_name,
          description: `'${JSON.stringify(description)}'`,
        };

        return API.request(API.endpoints.UPDATE_LOCATION_FULL, data);
      });

      try {
        await Promise.all(tasks);
      } catch (e) {
        console.log(e);
      }
    }

    const isChanged = () =>
      !items
        .map((item, index) => item.id === prevItems[index].id)
        .every(item => item);

    if (isChanged() && !collectedProps.isDragging) {
      setPrevItems([...items]);
      sendChanges();
    }
  }, [collectedProps.isDragging]);

  const move = async (sourceId, destinationId) => {
    moveItem(sourceId, destinationId);
  };

  const onLocationDelete = () => {
    loadLocationsOfCompany(companyDecoded);
  };

  return (
    <>
      <ContextMenu />
      <Row>
        <Col>
          <h1 className="mt-3 mb-3">Locations</h1>
        </Col>
      </Row>
      <Row>
        {companyData.isDownloaded && items.length > 0 && (
          <PlusItemCard onClick={createLocation} />
        )}
        {items.length > 0 &&
          items.map(item => (
            <DragItem key={item.id} id={item.id} onMoveItem={move}>
              <LocationItem
                data={item}
                clickHandler={console.log}
                hash={settings.hash}
                searchName={searchName}
                showContextMenu={showContextMenu}
                onDelete={onLocationDelete}
              />
            </DragItem>
          ))}
        {companyData.isDownloaded && items.length === 0 && (
          <EmptyPlus onClick={createLocation} />
        )}
      </Row>
    </>
  );
}

const mapStateToProps = state => ({
  companyData: state.company,
  settings: state.settings,
  searchName: state.searchName,
});

const mapDispatchToProps = {
  loadLocationsOfCompany,
  resetCompanyLocations,
  showContextMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(Multilocation);
