import { debug } from './debugUtils';
import { MagicTokenSingleton } from './MagicTokenSingleton';

const endpoints = {
  UPDATE_LOCATION_FULL: 'UPDATE_LOCATION_FULL',
  DELETE_LOCATION: 'DELETE_LOCATION',
  DELETE_LEVEL: 'DELETE_LEVEL',
  ADD_LOCATION: 'ADD_LOCATION',
  UPLOAD_MODEL: 'UPLOAD_MODEL',
  UPLOAD_MODEL_FILE: 'UPLOAD_MODEL_FILE',
  UPLOAD_AUDIO: 'UPLOAD_AUDIO',
  UPLOAD_ICONS: 'UPLOAD_ICONS',
  UPLOAD_PREVIEW: 'UPLOAD_PREVIEW',
  UPLOAD_LOGO: 'UPLOAD_LOGO',
  UPLOAD_FAVICON: 'UPLOAD_FAVICON',
  UPLOAD_MAP: 'UPLOAD_MAP',
  UPLOAD_FRAMES: 'UPLOAD_FRAMES',
  UPLOAD_PLAN: 'UPLOAD_PLAN',
  ADD_FURNITURE: 'ADD_FURNITURE',
  UPDATE_FURNITURE: 'UPDATE_FURNITURE',
  DELETE_FURNITURE: 'DELETE_FURNITURE',
  ADD_HOTSPOT: 'ADD_HOTSPOT',
  UPDATE_HOTSPOT: 'UPDATE_HOTSPOT',
  DELETE_HOTSPOT: 'DELETE_HOTSPOT',
  COPY_LOCATION: 'COPY_LOCATION',
  UPLOAD_TILES: 'UPLOAD_TILES',
  CANCEL_TILES: 'CANCEL_TILES',
  UPLOAD_EQUI: 'UPLOAD_EQUI',
  LOGIN_ADMIN: 'LOGIN_ADMIN',
  ADD_RECORD: 'ADD_RECORD',
  GET_ACTIVE_RECORD: 'GET_ACTIVE_RECORD',
  UPLOAD_ONE_FILE: 'UPLOAD_ONE_FILE',
  DELETE_COMPANY: 'DELETE_COMPANY',
  DELETE_MULTILOCATION: 'DELETE_MULTILOCATION',
  CREATE_COMPANY: 'CREATE_COMPANY',
  CREATE_MULTILOCATION: 'CREATE_MULTILOCATION',
  UPDATE_MULTILOCATION: 'UPDATE_MULTILOCATION',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  DELETE_ICON: 'DELETE_ICON',
  MESH_GENERATION_UPLOAD: 'MESH_GENERATION_UPLOAD',
  DELETE_MAP: 'DELETE_MAP',
  CLEAR_LOCATION: 'CLEAR_LOCATION',
};

const endpointBase = `${process.env.REACT_APP_BACKEND_URL}/api`;
const endpointTilegen = `${process.env.REACT_APP_TILEGEN_URL}`;
const endpointMeshgen = `${process.env.REACT_APP_MESHGEN_URL}`;

const endpointData = {
  UPDATE_LOCATION_FULL: {
    url: `${endpointBase}/updateLocationById`,
    method: 'POST',
  },
  DELETE_MAP: {
    url: `${endpointBase}/deleteMap`,
    method: 'POST',
  },
  DELETE_LOCATION: {
    url: `${endpointBase}/deleteLocation`,
    method: 'POST',
  },
  DELETE_LEVEL: {
    url: `${endpointBase}/deleteFramesFolderInLocation`,
    method: 'POST',
  },
  ADD_LOCATION: {
    url: `${endpointBase}/addLocation`,
    method: 'POST',
  },
  UPLOAD_MODEL: {
    url: `${endpointBase}/addFileToLocation`,
    method: 'POST',
  },
  UPLOAD_MODEL_FILE: {
    url: `${endpointBase}/addOneFileToLocation`,
    method: 'POST',
  },
  UPLOAD_AUDIO: {
    url: `${endpointBase}/addAudioToLocation`,
    method: 'POST',
  },
  UPLOAD_ICONS: {
    url: `${endpointBase}/addIconToLocation`,
    method: 'POST',
  },
  UPLOAD_PREVIEW: {
    url: `${endpointBase}/addImageToLocation`,
    method: 'POST',
  },
  UPLOAD_FAVICON: {
    url: `${endpointBase}/addFaviconToLocation`,
    method: 'POST',
  },
  UPLOAD_LOGO: {
    url: `${endpointBase}/addLogoToLocation`,
    method: 'POST',
  },
  UPLOAD_MAP: {
    url: `${endpointBase}/addMapToLocation`,
    method: 'POST',
  },
  UPLOAD_FRAMES: {
    url: `${endpointBase}/addFramesToLocation`,
    method: 'POST',
  },
  UPLOAD_PLAN: {
    url: `${endpointBase}/addPlanToLocation`,
    method: 'POST',
  },
  ADD_FURNITURE: {
    url: `${endpointBase}/addFurniture`,
    method: 'POST',
  },
  UPDATE_FURNITURE: {
    url: `${endpointBase}/updateFurniture`,
    method: 'POST',
  },
  DELETE_FURNITURE: {
    url: `${endpointBase}/deleteFurniture`,
    method: 'POST',
  },
  ADD_HOTSPOT: {
    url: `${endpointBase}/addHotspot`,
    method: 'POST',
  },
  UPDATE_HOTSPOT: {
    url: `${endpointBase}/updateHotspot`,
    method: 'POST',
  },
  CLEAR_LOCATION: {
    url: `${endpointBase}/clearLocation`,
    method: 'POST',
  },
  DELETE_HOTSPOT: {
    url: `${endpointBase}/deleteHotspot`,
    method: 'POST',
  },
  COPY_LOCATION: {
    url: `${endpointBase}/copyLocation`,
    method: 'POST',
  },
  UPLOAD_EQUI: {
    url: `${endpointTilegen}/upload/equi`,
    method: 'POST',
  },
  UPLOAD_TILES: {
    url: `${endpointTilegen}/process/cubemap`,
    method: 'POST',
  },
  CANCEL_TILES: {
    url: `${endpointTilegen}/jobs/remove`,
    method: 'GET',
  },
  LOGIN_ADMIN: {
    url: `${endpointBase}/login/admin`,
    method: 'POST',
  },
  ADD_RECORD: {
    url: `${endpointBase}/addRecord`,
    method: 'POST',
  },
  GET_ACTIVE_RECORD: {
    url: `${endpointBase}/getActiveRecord`,
    method: 'GET',
  },
  UPLOAD_ONE_FILE: {
    url: `${endpointBase}/addOneFileToLocation`,
    method: 'POST',
  },
  DELETE_COMPANY: {
    url: `${endpointBase}/deleteCompanyById`,
    method: 'POST',
  },
  DELETE_MULTILOCATION: {
    url: `${endpointBase}/deleteMultiTourById`,
    method: 'POST',
  },
  CREATE_COMPANY: {
    url: `${endpointBase}/createCompany`,
    method: 'POST',
  },
  CREATE_MULTILOCATION: {
    url: `${endpointBase}/createMultilocation`,
    method: 'POST',
  },
  UPDATE_MULTILOCATION: {
    url: `${endpointBase}/updateMultilocation`,
    method: 'POST',
  },
  UPDATE_COMPANY: {
    url: `${endpointBase}/updateCompany`,
    method: 'POST',
  },
  MESH_GENERATION_UPLOAD: {
    url: `${endpointMeshgen}/uploadFilesMeshGenerate`,
    method: 'POST',
  },
};

async function request(endpoint, data, query = '') {
  const { url, method } = endpointData[endpoint];
  const options = { method };

  const headers = {};

  if (method === 'POST') {
    headers['X-Access-Token'] = `Bearer ${MagicTokenSingleton.I.getToken()}`;
  }

  const isFormData = data && data instanceof FormData;

  if (data && !isFormData) {
    options.body = JSON.stringify(data);
    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';
    options.headers = headers;
  } else if (data) {
    options.body = data;
    options.headers = headers;
  }

  const response = await fetch(url + query, options);
  const json = await response.json();
  debug(() => {
    console.group(`API request: ${endpoint}`);
    console.info(json);
    console.groupEnd();
  });

  if (json.errors) {
    throw new Error(json.errors);
  } else if (json.error) {
    throw new Error(json.error);
  } else if (!json.result) {
    throw new Error('Response is emply');
  } else if (
    (!json.result.message || json.result.message !== 'success') &&
    endpoint !== endpoints.LOGIN_ADMIN &&
    method !== 'GET'
  ) {
    let msg;
    try {
      msg = JSON.stringify(json.result);
    } catch (e) {
      msg = json.result;
    }
    throw new Error(msg);
  }

  return json;
}

export const existsOnServer = async url => {
  try {
    const res = await fetch(url, { method: 'GET', mode: 'cors' });

    return res.status === 200;
  } catch (e) {
    return false;
  }
};

async function requestWithXHR(endpoint, data, onProgress) {
  const { url, method } = endpointData[endpoint];
  const options = { method };
  const headers = {};
  const isFormData = data && data instanceof FormData;

  if (data && !isFormData) {
    options.body = JSON.stringify(data);
    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';
    options.headers = headers;
  } else if (data) {
    options.body = data;
    options.headers = headers;
  }

  if (method === 'POST') {
    headers['X-Access-Token'] = `Bearer ${MagicTokenSingleton.I.getToken()}`;
  }

  const request = new XMLHttpRequest();

  request.open(options.method, url);
  for (const [header, value] of Object.entries(headers)) {
    request.setRequestHeader(header, value);
  }

  return new Promise(resolve => {
    request.addEventListener('load', e => {
      resolve(request.response);
    });

    request.addEventListener('error', e => {
      throw new Error(e.target.status);
    });

    request.upload.onprogress = evt => {
      if (evt.lengthComputable) {
        onProgress(evt.loaded / evt.total);
      }
    };

    request.send(options.body);
  });
}

export const getLocationById = id => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api`;

  return fetch(
    `${endpoint}/getLocationById?locationId=${id}&hash=${Date.now()}`
  ).then(response => response.json());
};

export const getLocationDescription = id => {
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api`;

  return fetch(
    `${endpoint}/getLocationById?locationId=${id}&hash=${Date.now()}`
  )
    .then(response => response.json())
    .then(result => {
      const points =
        result.result[0].description.LOCATION_POINT_SHORT_INFO || [];
      const fixedPoints = points.map(point => {
        if (typeof point === 'string') {
          return {
            name: point,
            x: 0,
            y: 0,
          };
        }

        return point;
      });

      return {
        ...result.result[0].description,
        LOCATION_POINT_SHORT_INFO: fixedPoints,
      };
    });
};

export default {
  endpoints,
  request,
  requestWithXHR,
  getLocationDescription,
  getLocationById,
  existsOnServer,
};
