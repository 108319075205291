import React from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { Col, Card } from 'react-bootstrap';
import Highlight from 'react-highlighter';

function Folder({ name, show, searchName, showContextMenu, contextMenuProps }) {
  const { url } = useRouteMatch();

  const contextMenuHandler = e => {
    e.preventDefault();
    showContextMenu({
      ...contextMenuProps,
      offsetX: e.pageX,
      offsetY: e.pageY,
    });
  };

  const getName = name => {
    try {
      return decodeURIComponent(name);
    } catch (e) {
      return name;
    }
  };

  const link = (url === '/' ? '' : url + '/') + name;

  const skipRegex = () => {
    const symbols = ['?', '.', '&', '(', ')', '[', ']', '*', '$'];

    return symbols.reduce(
      (escaped, symbol) => escaped.replace(symbol, '\\' + symbol),
      searchName
    );
  };

  return (
    <>
      {show && (
        <Col xs={12} md={4} sm={4} xl={3} lg={3} className="location-item mb-4">
          <Link className="nav-link" to={link}>
            <Card
              style={{ border: 0, boxShadow: 'none' }}
              onContextMenu={contextMenuHandler}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/folder.png`}
                alt="folder"
              />
              <div className="d-flex justify-content-center">
                {searchName && (
                  <Highlight
                    search={
                      new RegExp(
                        `${skipRegex(searchName).toLowerCase()}|${skipRegex(searchName)}`
                      )
                    }
                  >
                    {getName(name)}
                  </Highlight>
                )}
                {!searchName && <div>{getName(name)}</div>}
              </div>
            </Card>
          </Link>
        </Col>
      )}
    </>
  );
}

Folder.propTypes = {
  name: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  searchName: PropTypes.string.isRequired,
};

export default Folder;
