import React from 'react';
import { Col, Row, Form, Collapse, Button } from 'react-bootstrap';
import { Field, FieldArray, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import CloseIcon from 'mdi-react/CloseIcon';

import InfoTooltipIcon from '../InfoTooltipIcon';
import renderInlineField from './InlineField';
import { PointTypes, PointNames } from '../../../constants';

const renderPointLine = ({
  fields,
  point,
  index,
  dispatch,
  LOCATION_POINT_SHORT_INFO,
}) => {
  // const clearPortal = idx => {
  // dispatch(change('update_location', `LOCATION_POINT_SHORT_INFO[${idx}]`, {...LOCATION_POINT_SHORT_INFO[idx], portalId: '', portalPoint: ''}))
  // }

  const clearPortal = i => {
    console.log(LOCATION_POINT_SHORT_INFO[index].markers[i]);

    dispatch(
      change(
        'update_location',
        `LOCATION_POINT_SHORT_INFO[${index}].markers[${i}]`,
        {
          ...LOCATION_POINT_SHORT_INFO[index].markers[i],
          portalId: '',
          portalPoint: '',
        }
      )
    );
  };

  const clearHotspotId = i => {
    dispatch(
      change(
        'update_location',
        `LOCATION_POINT_SHORT_INFO[${index}].markers[${i}]`,
        { ...LOCATION_POINT_SHORT_INFO[index].markers[i], hotspotId: '' }
      )
    );
  };

  const clearScale = i => {
    dispatch(
      change(
        'update_location',
        `LOCATION_POINT_SHORT_INFO[${index}].markers[${i}]`,
        { ...LOCATION_POINT_SHORT_INFO[index].markers[i], scale: 1 }
      )
    );
  };

  return (
    <React.Fragment>
      <Col className="mb-3">
        <Form.Group as={Row}>
          <Form.Label column sm={1} className="text-center font-weight-bold">
            {`${index + 1}`}
          </Form.Label>
          <Field
            name={`${point}.name`}
            type="text"
            label={`Point #${index + 1}`}
            component={renderInlineField}
            span={2}
          />
          <Col sm={1}>
            <Button
              onClick={() => fields.remove(fields.length - 1)}
              disabled={!fields.length}
              style={{ height: '38px' }}
            >
              <MinusIcon />
            </Button>
          </Col>
          <Col sm={1}>
            <Button onClick={() => fields.push({})} style={{ height: '38px' }}>
              <PlusIcon />
            </Button>
          </Col>
        </Form.Group>
      </Col>
      {fields.length > 0 && (
        <Col>
          <Row className="pb-2">
            <Col className="pl-4" sm={{ span: 1, offset: 1 }}>
              X
            </Col>
            <Col className="pl-4" sm={1}>
              Y
            </Col>
            <Col className="pl-4" sm={1}>
              Z
            </Col>
            <Col className="pl-4" sm={2}>
              Badge
            </Col>
            <Col className="pl-4" sm={2}>
              Transition{' '}
              <InfoTooltipIcon
                tooltip={
                  <div>
                    <div className="pb-1">
                      Opening another location after moving to this point.
                    </div>
                    <div className="text-left">
                      - ID: location to transition
                    </div>
                    <div className="text-left">
                      - #: point in a new location
                    </div>
                  </div>
                }
              />
            </Col>
            <Col className="pl-4" sm={2}>
              Hotspot view
            </Col>
            {/* <Col className='pl-4' sm={2}>
              Scale
            </Col> */}
          </Row>
        </Col>
      )}
      {fields.map((marker, i) => (
        <Col className="mb-3" key={marker}>
          <Form.Group as={Row}>
            <Field
              name={`${marker}.x`}
              type="text"
              placeholder="X"
              component={renderInlineField}
              span={1}
              offset={1}
            />
            <Field
              name={`${marker}.y`}
              type="text"
              placeholder="Y"
              component={renderInlineField}
              span={1}
            />
            <Field
              name={`${marker}.z`}
              type="text"
              placeholder="Z"
              component={renderInlineField}
              span={1}
            />
            <Field
              name={`${marker}.icon`}
              component={renderInlineField}
              span={2}
              as="select"
            >
              <option value={PointTypes.HELI}>
                {PointNames[PointTypes.HELI]}
              </option>
              <option value={PointTypes.GYM}>
                {PointNames[PointTypes.GYM]}
              </option>
              <option value={PointTypes.HOME}>
                {PointNames[PointTypes.HOME]}
              </option>
              <option value={PointTypes.POOL}>
                {PointNames[PointTypes.POOL]}
              </option>
              <option value={PointTypes.SOFA}>
                {PointNames[PointTypes.SOFA]}
              </option>
              <option value={PointTypes.ARROW_UP}>
                {PointNames[PointTypes.ARROW_UP]}
              </option>
              <option value={PointTypes.ARROW_LEFT}>
                {PointNames[PointTypes.ARROW_LEFT]}
              </option>
              <option value={PointTypes.ARROW_RIGHT}>
                {PointNames[PointTypes.ARROW_RIGHT]}
              </option>
              <option value={PointTypes.PREVIEW}>
                {PointNames[PointTypes.PREVIEW]}
              </option>
            </Field>
            <Field
              name={`${marker}.portalId`}
              type="text"
              placeholder="ID"
              component={renderInlineField}
              span={1}
              onChange={() => clearHotspotId(i)}
            />
            <Field
              name={`${marker}.portalPoint`}
              type="text"
              placeholder="#"
              component={renderInlineField}
              span={1}
              onChange={() => clearHotspotId(i)}
            />
            <Field
              name={`${marker}.hotspotId`}
              type="number"
              placeholder="0"
              component={renderInlineField}
              span={2}
              onChange={() => clearPortal(i)}
            />
            {/* <Field
                name={`${marker}.scale`}
                type='number'
                placeholder='0'
                component={renderInlineField}
                span={2}
                onChange={() => clearScale(i)}
              /> */}
            <Col className="pl-4" sm={1}>
              <Button
                onClick={() => fields.remove(i)}
                style={{ height: '38px' }}
                variant="danger"
              >
                <CloseIcon />
              </Button>
            </Col>
          </Form.Group>
        </Col>
      ))}
    </React.Fragment>
  );
};

const PointFieldsInstant = ({
  fields,
  show,
  dispatch,
  LOCATION_POINT_SHORT_INFO,
}) => (
  <Collapse in={show}>
    <div
      className="mt-4 mb-4 pt-4 pb-3"
      style={{ backgroundColor: 'rgba(200,200,200,0.2)' }}
    >
      <Col>
        <Row className="pb-2">
          <Col className="pl-4" sm={1}>
            Point
          </Col>
          <Col className="pl-4" sm={2}>
            Title
          </Col>
          <Col className="pl-4" sm={2}>
            Markers
          </Col>
        </Row>
      </Col>

      {fields.map((point, index) => (
        <FieldArray
          name={`${point}.markers`}
          component={renderPointLine}
          point={point}
          index={index}
          key={point}
          dispatch={dispatch}
          LOCATION_POINT_SHORT_INFO={LOCATION_POINT_SHORT_INFO}
        />
      ))}
    </div>
  </Collapse>
);

const selector = formValueSelector('update_location');

renderPointLine.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => {
  return {
    LOCATION_POINT_SHORT_INFO: selector(state, 'LOCATION_POINT_SHORT_INFO'),
  };
})(PointFieldsInstant);
