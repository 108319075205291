import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Row, Col, Form, Button, CardGroup, Modal } from 'react-bootstrap';
import AddIcon from 'mdi-react/AddIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';

import renderDropZoneField from '../../../shared/components/form/DropZone';
import validate from './validate';
import renderField from '../../../shared/components/form/Field';
import renderCheckbox from '../../../shared/components/form/Checkbox';

import { HotspotNames, HotspotTypes } from '../../../constants';

const maxLength = max => value =>
  value && value.length > max
    ? `Must be ${max} characters long or less`
    : undefined;

const maxLength60 = maxLength(60);
const maxLength430 = maxLength(430);

const renderGalleryItem = ({ fields }) => {
  return (
    <React.Fragment>
      <Col>
        <Row className="mb-3">
          {/* <Col></Col> */}
          <Col xs="auto">
            <Button variant="success" onClick={() => fields.push('')}>
              <AddIcon />
            </Button>
          </Col>
        </Row>
        {fields.map((item, index) => (
          <Row key={item}>
            <Col>
              <Field name={item} type="text" component={renderField} />
            </Col>
            <Col xs="auto">
              <Button
                variant="danger"
                onClick={() => fields.remove(index)}
                style={{ height: '38px' }}
              >
                <DeleteIcon />
              </Button>
            </Col>
          </Row>
        ))}
      </Col>
    </React.Fragment>
  );
};

const HotspotSettings = ({
  handleSubmit,
  edit,
  initialValues,
  invalid,
  uploading,
}) => {
  // const [imageSelected, setImageSelected] = useState(null);
  const [type, setType] = useState(initialValues.type || HotspotTypes.IMAGE);

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <Field
          name="type"
          type="text"
          label="Type"
          as="select"
          component={renderField}
          onChange={event => setType(event.target.value)}
          defaultValue={HotspotTypes.IMAGE}
        >
          {Object.values(HotspotTypes).map(hotspotType => (
            <option key={hotspotType} value={hotspotType}>
              {HotspotNames[hotspotType]}
            </option>
          ))}
        </Field>
        <Field
          name="title"
          type="text"
          label="Title"
          component={renderField}
          validate={[maxLength60]}
        />
        {(type === HotspotTypes.TEXT || type === HotspotTypes.IMAGE) && (
          <Field
            name="subtitle"
            type="text"
            label="Subtitle"
            component={renderField}
          />
        )}
        {type !== HotspotTypes.GALLERY && (
          <Field
            name="main_text"
            type="text"
            as={type === HotspotTypes.FURNITURE ? 'input' : 'textarea'}
            validate={[maxLength430]}
            label={type === HotspotTypes.FURNITURE ? 'Price' : 'Description'}
            component={renderField}
          />
        )}
        {type === HotspotTypes.GALLERY && (
          <FieldArray name="items" component={renderGalleryItem} />
        )}
        {type === HotspotTypes.IFRAME && (
          <Field
            name="iframe_link"
            type="text"
            label="Link"
            placeholder="Location link for iframe"
            component={renderField}
          />
        )}
        {type === HotspotTypes.IFRAME && (
          <Field
            name="full_screen_mode"
            type="checkbox"
            label="Full screen"
            component={renderCheckbox}
          />
        )}
        {(type === HotspotTypes.IMAGE || type === HotspotTypes.FURNITURE) && (
          <React.Fragment>
            <Field
              name="button_text"
              type="text"
              label="Button"
              placeholder={
                type === HotspotTypes.IMAGE ? 'Learn more' : 'Purchase'
              }
              component={renderField}
            />
            <Field
              name="link"
              type="text"
              label="Link"
              placeholder="Button link"
              component={renderField}
            />
            <Row>
              <Col>
                <CardGroup>
                  <Field
                    name="image"
                    component={renderDropZoneField}
                    image={
                      initialValues && initialValues.image
                        ? `${process.env.REACT_APP_MEDIA_URL}/${initialValues.image}`
                        : ''
                    }
                    accept=".jpg,.png"
                    style={{ width: '100%', maxWidth: '500px' }}
                    // onChange={() => setImageSelected(true)}
                  />
                </CardGroup>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          disabled={
            uploading || invalid
            // !(imageSelected || (initialValues && initialValues.image))
          }
        >
          {edit ? 'Done' : 'Add'}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

HotspotSettings.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'update_hotspot',
  validate,
})(HotspotSettings);
