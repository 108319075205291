import * as actionTypes from '../actions/types';

export default function TileReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.LOAD_TILE_STATS: {
      return { stats: action.payload.stats };
    }
    default:
      return state;
  }
}
