const systemFiles = ['desktop.ini', '.DS_store', 'Thumbs.db', '.DS_Store'];

export function getEquiFiles(zip) {
  return zip.filter(
    f =>
      f.filename.startsWith('equi') &&
      !f.directory &&
      !f.filename.match(new RegExp(systemFiles.join('|')))
  );
}

function validateBase(zip, filename) {
  const isValidEquiFile = file => {
    return !!/equi\/eq_\d*\.jpg/.test(file);
  };

  const hasEquiFolder = !!zip.find(f => f.filename.startsWith('equi/'));
  const isZip = filename.endsWith('.zip');
  const equiFiles = getEquiFiles(zip);
  const isEquiFilesValid = equiFiles.every((f) => isValidEquiFile(f.filename));

  if (!isZip) {
    throw new Error('Incorrect archive format. Only zip archives are allowed.');
  }
  if (!hasEquiFolder) {
    throw new Error(
      'Unable to find equi panomaras in the archive.' +
        ' Please check if panoramas are present and located in "equi" folder.'
    );
  }
  if (!isEquiFilesValid) {
    const incorrectFileNames = equiFiles
      .filter(f => !isValidEquiFile(f.filename))
      .map(f => f.filename);

    const error =
      new Error(`equi panoramas are not present or named incorrectly.
     Please check equi naming, it should be: eq_1.jpg, eq_2.jpg...\n Found ${incorrectFileNames.join(
       ', '
     )}`);
    error.heading = 'Naming error: ';
    throw error;
  }
}

export function validateArchive3d(zip, filename) {
  validateBase(zip, filename);

  const hasGltf = !!zip.find(f => f.filename.startsWith('location.gltf'));
  const hasBin = !!zip.find(f => f.filename.startsWith('location.bin'));
  const meshFiles = zip.filter(
    f =>
      f.filename.startsWith('mesh') &&
      !f.directory &&
      !f.filename.match(new RegExp(systemFiles.join('|')))
  );
  const isValidMeshFiles = meshFiles.every(f =>
    /mesh_?\d+.jpg/.test(f.filename)
  );

  if (!isValidMeshFiles) {
    throw new Error(
      'Naming error: The file extension of all images must be "jpg" with small letters'
    );
  }

  if (!hasGltf) {
    throw new Error('Unable to find location.gltf. Please check your archive.');
  }
  if (!hasBin) {
    throw new Error('Unable to find location bin. Please check your archive.');
  }
}

export function validateArchiveMeshGenerate(zip, filename) {
  const isValidEqFile = file => {
    return !!/eq_\d+\.(exr|jpg)/.test(file);
  };

  const hasCoordTxt = !!zip.find(f => f.filename === 'coord.txt');
  const hasEqFiles = !!zip.find(f => isValidEqFile(f.filename));
  const isZip = filename.endsWith('.zip');

  if (!isZip) {
    throw new Error('Incorrect archive format. Only zip archives are allowed.');
  }
  if (!hasCoordTxt) {
    throw new Error(
      'Unable to find "coord.txt" in the archive. Please check if the file is present.'
    );
  }
  if (!hasEqFiles) {
    throw new Error(
      'Unable to find equi panomaras (eq_{n}.exr or eq_{n}.jpg) in the archive.' +
        ' Please check if panoramas are present and located in the correct format.'
    );
  }

  const eqFiles = zip.filter(
    f => f.filename.match(/eq_\d+\.(exr|jpg)/) && !f.directory
  );

  const eqFileNumbers = new Set(
    eqFiles.map(f => parseInt(f.filename.match(/\d+/)[0]))
  );
  for (const number of eqFileNumbers) {
    const exrFileName = `eq_${number}.exr`;
    const jpgFileName = `eq_${number}.jpg`;
    if (
      !eqFiles.some(f => f.filename === exrFileName) ||
      !eqFiles.some(f => f.filename === jpgFileName)
    ) {
      throw new Error(
        `Missing either ${exrFileName} or ${jpgFileName}. Make sure both files are present.`
      );
    }
  }
}

export function validateArchive2d(zip, filename) {
  validateBase(zip, filename);
}

/**
 * Function to validate a ZIP archive.
 *
 * @param {zipjs.ZipReader} zip - The ZIP archive to validate.
 * @param {string} filename - The filename of the archive.
 *
 * @throws {Error} - If validation fails.
 */
export const validateFramesZip = (zip, filename) => {
  // Check if the filename ends with '.zip'
  if (!filename.endsWith('.zip')) {
    throw new Error('Incorrect archive format. Only zip archives are allowed.');
  }

  // Check if the archive has any entries
  if (zip.length === 0) {
    throw new Error(
      'Archive is empty. Please ensure the archive contains files.'
    );
  }

  // Validate file extensions and naming convention for all entries
  const validExtensions = new Set(['png', 'webp', 'jpg', 'jpeg']);
  const expectedPattern = /^frame_(\d+)\.(png|webp|jpg|jpeg)$/;
  for (const entry of zip) {
    const filename = entry.filename.replace(/.*\//, '');
    const match = filename.match(expectedPattern);
    if (!filename) continue;

    if (!match) {
      throw new Error(
        `Invalid file name: ${filename}. Expected format: frame_{number}.{extension}`
      );
    }

    const fileNumber = parseInt(match[1]);
    if (isNaN(fileNumber) || fileNumber <= 0) {
      throw new Error(
        `Invalid file number in: ${filename}. Number must be a positive integer.`
      );
    }

    const extension = match[2].toLowerCase();
    if (!validExtensions.has(extension)) {
      throw new Error(
        `Invalid file extension found: ${extension}. Only PNG and WEBP files are allowed.`
      );
    }
  }
};

  

const findDifferingFiles = (array1, array2) => {
  const differingFiles = [];
  const filesSet = new Set([...array1, ...array2]);

  for (const file of filesSet) {
      if (!array1.includes(file) || !array2.includes(file)) {
          differingFiles.push(file);
      }
  }

  return differingFiles;
}

