import * as actionTypes from '../actions/types';

const initialState = {
  authenticated: false,
  loaded: false,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.AUTH_LOGIN: {
      return {
        ...action.payload.auth,
        authenticated: true,
        loaded: true,
      };
    }
    case actionTypes.AUTH_LOGOUT: {
      return {
        ...initialState,
        loaded: true,
      };
    }
    default:
      return state;
  }
}
