import React, { useState, useMemo } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Row, Col, Button, CardGroup } from 'react-bootstrap';

import AlertTooltipIcon from '../../../shared/components/AlertTooltipIcon';
import renderDropZoneField from '../../../shared/components/form/DropZone';

const PreviewFileInput = ({ handleSubmit, errorText, uploading, image }) => {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const submitButtonDisabled = useMemo(
    () => uploading || !isFileSelected || errorText,
    [uploading, isFileSelected]
  );

  const alert = !image ? <AlertTooltipIcon tooltip="No images uploaded" /> : '';

  return (
    <Row className="pt-3 pb-3 mb-3 bg-light">
      <Col>
        <Row>
          <Col className="mb-2">
            <h5>
              Preview <span className="text-muted">(.jpg)</span> {alert}
            </h5>
          </Col>
          <Col>
            <Button
              onClick={handleSubmit}
              size="sm"
              disabled={submitButtonDisabled}
              style={{ minWidth: '100px' }}
            >
              {uploading ? 'Uploading...' : 'Upload'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardGroup>
              <Field
                name="preview"
                onChange={() => setIsFileSelected(true)}
                component={renderDropZoneField}
                image={
                  image ? `${process.env.REACT_APP_MEDIA_URL}/${image}` : ''
                }
                base64
                accept=".jpg"
                maxSize={10}
              />
            </CardGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

PreviewFileInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
};

PreviewFileInput.defaultProps = {
  uploading: false,
};

export default reduxForm({
  form: 'upload_preview',
})(PreviewFileInput);
