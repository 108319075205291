import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Toast from './components/Toast';

function Toasts({ toastIds }) {
  const toasts = toastIds.map(id => <Toast id={id} key={id} />);

  return <div className="toasts">{toasts}</div>;
}

Toasts.propTypes = {
  toastIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};

export default connect(state => ({
  toastIds: state.toasts.map(toast => toast.id),
}))(Toasts);
