import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as zipjs from '@zip.js/zip.js';

import { debug } from './debugUtils';
import generateGLTF from './generateGLTF';
import { ZipFileInputStorage } from '../../containers/LocationSettings/components/ZipFileInput';
import { validateArchive3d } from './validate';

zipjs.configure({ useWebWorkers: false });

export const unzipAsText = (zip, path, onprogress) =>
  zip
    .find(f => f.filename === path)
    .getData(new zipjs.TextWriter(), { onprogress });

export const unzipAsArrayBuffer = async (zip, path, onprogress = () => {}) => {
  const blob = await zip
    .find(f => f.filename === path)
    .getData(new zipjs.BlobWriter(), { onprogress });

  return blob.arrayBuffer();
};

export default async function parseModel(
  file,
  locationDescription,
  noPanos,
  updateHandler
) {
  const progressbar = {
    totalFiles: 0,
    filesLoaded: 0,
    getProgress: () =>
      ~~((progressbar.filesLoaded / progressbar.totalFiles) * 100),
  };

  return new Promise(async (resolve, reject) => {
    try {
      debug(() => console.groupCollapsed('ZIP file/GLTF parsing'));
      const loader = new GLTFLoader();
      const file_bin = 'location.bin';
      const file_gltf = 'location.gltf';
      const url = '/';
      THREE.Cache.enabled = true;
      const dateBefore = Date.now();
      const reader = new zipjs.ZipReader(new zipjs.BlobReader(file));
      const zip = await reader.getEntries();

      validateArchive3d(zip, file.name);

      // if (!zip.filter(f => f.filename === file_gltf)) {
      // if (!zip.files['location.gltf']) {
      //   console.log('No GLTF file detected, adding one...');
      //   const files = generateGLTF(zip);
      //   ZipFileInput.newFiles.push(...files);
      //   console.log('GLTF file added.');
      // }

      // Select files from root directory only
      const textures = zip
        .filter(f => !f.filename.includes('/') && f.filename.includes('.jpg'))
        .map(f => f.filename);
      const eqImages = zip
        .filter(f => f.filename.includes('equi/eq_'))
        .map(f =>
          Number(f.filename.replace('equi/eq_', '').replace('.jpg', ''))
        );
      const cubeImages = zip
        .filter(f => f.filename.includes('cubefront'))
        .map(f =>
          Number(f.filename.replace('cube/', '').replace('_cubefront.jpg', ''))
        );

      const panPrefix = 'equi/eq_';
      // select all equirectangular pans
      const equiTextures = zip
        .filter(f => f.filename.startsWith(panPrefix))
        .map(f => f.filename);

      const hasGLTF = zip.find(f => f.filename === file_gltf);
      const filesList = [...textures];

      if (hasGLTF) {
        filesList.push(file_bin);
      }

      progressbar.totalFiles = equiTextures.length + filesList.length + 1; // 1 for GLTF file

      const removeTextureLinks = gltf => {
        try {
          gltf = JSON.parse(gltf);

          if (gltf.materials) {
            for (const material of gltf.materials) {
              material.pbrMetallicRoughness.baseColorTexture = undefined;
            }
          }

          return JSON.stringify(gltf);
        } catch (e) {
          throw new Error(
            'Mesh error: unable to process location.gltf, please check your location model'
          );
        }
      };

      if (hasGLTF) {
        const gltfData = await unzipAsText(zip, file_gltf, (index, total) => {
          const progress = index / total / progressbar.totalFiles;
          updateHandler(
            file_gltf,
            Math.floor(progress * 100) * progressbar.getProgress()
          );
        });
        // mesh path fix
        const gltfDataFixed = gltfData.split('uri":"./').join('uri":"');
        const gltfNoTextures = removeTextureLinks(gltfDataFixed);
        THREE.Cache.add(`${url}/${file_gltf}`, gltfNoTextures);
      } else {
        const files = generateGLTF(zip);
        ZipFileInputStorage.newFiles.push(...files);
        console.log('GLTF file added.');
        console.log(files);
        THREE.Cache.add(`${url}/${file_gltf}`, files[0][1]);
      }

      progressbar.filesLoaded++;

      for (const panName in equiTextures) {
        const item = equiTextures[panName];
        const data = await unzipAsArrayBuffer(zip, item, (index, total) =>
          updateHandler(item, progressbar.getProgress())
        );

        THREE.Cache.add('pan' + parseInt(item.slice(panPrefix.length)), data);

        progressbar.filesLoaded++;
      }

      // Unzip other files
      for (const i in filesList) {
        const item = filesList[i];

        const data = await unzipAsArrayBuffer(zip, item, (index, total) =>
          updateHandler(item, progressbar.getProgress())
        );

        THREE.Cache.add(`${url}/${item}`, data);

        progressbar.filesLoaded++;
      }
      debug(() => console.info('Cache:'));
      Object.keys(THREE.Cache.files).forEach(key => console.log(key));

      const loadAsync = () => {
        return new Promise((rs, rj) => {
          loader.load(
            `${url}/${file_gltf}`,
            async gltf => {
              const points = [];
              const furniture = [];
              gltf.scene.children.forEach(child => {
                const pointMatch = child.name.match(/Sphere(\d{1,5})/);
                const furnitureMatch = child.name.match(/Selectable_(\d{1,5})/);
                if (pointMatch) {
                  points[Number(pointMatch[1]) - 1] = {
                    position: child.position,
                    noImage: noPanos
                      ? false
                      : !eqImages.includes(Number(pointMatch[1])) &&
                        !cubeImages.includes(Number(pointMatch[1])),
                  };
                } else if (furnitureMatch) {
                  furniture.push([Number(furnitureMatch[1])]);
                }
              });
              console.info(`${points.length} points`);
              console.info(`${furniture.length} furniture items`);
              console.info(`processed in ${Date.now() - dateBefore}ms`);
              debug(() => console.groupEnd());

              const minimaps = locationDescription.MAP_IMAGES || {};
              let aspectRatio = 1;

              if (Object.values(minimaps).length > 0) {
                const url =
                  process.env.REACT_APP_MEDIA_URL +
                  '/' +
                  Object.values(minimaps)[0];
                const { width, height } = await getImageResolution(url);

                aspectRatio = width / height;
              }

              const minimapPoints = getMinimapPoints(gltf, points, 1);

              rs({ points, minimapPoints, furniture });
            },
            () => {},
            err => {
              rj({
                message:
                  'Mesh error: unable to process location.gltf, please check your location model',
              });
            }
          );
        });
      };
      const res = await loadAsync();
      resolve(res);
    } catch (error) {
      debug(() => console.groupEnd());
      reject(error);
    }
  });
}

function getMinimapPoints(gltf, points, cameraAspect) {
  if (gltf.cameras.length < 1) {
    return null;
  }

  const camera = gltf.cameras[0];
  const result = [];

  camera.aspect = cameraAspect;

  camera.updateProjectionMatrix();

  if (camera.parent && camera.parent instanceof THREE.Object3D) {
    camera.parent.updateMatrix();
    camera.parent.updateMatrixWorld();
  }

  camera.updateMatrixWorld();

  for (const { position } of points) {
    const p = position.clone();

    p.project(camera);

    result.push(p);
  }

  return result;
}

export async function getImageResolution(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      resolve({ width: image.width, height: image.height });
    };

    image.onerror = () => {
      resolve({ width: 1024, height: 1024 });
    };
  });
}
