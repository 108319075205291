import React from 'react';
import { Col } from 'react-bootstrap';

function PlusItemCard({ onClick }) {
  return (
    <Col
      className="location-item mb-4 d-flex justify-content-center align-items-center"
      xs={12}
      md={4}
      sm={4}
      xl={3}
      lg={3}
      onClick={onClick}
    >
      <img
        src={`${process.env.PUBLIC_URL}/img/plus.svg`}
        alt="plus"
        className="plus-icon"
        style={{ height: '50%', width: '50%' }}
      />
    </Col>
  );
}

export default PlusItemCard;
