import * as actionTypes from '../actions/types';

const initialState = {
  value: [],
  loaded: false,
};

export default function CompanyListReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_COMPANIES: {
      return {
        value: action.payload.companies,
        loaded: true,
      };
    }
    case actionTypes.RESET_LOCATIONS: {
      return initialState;
    }
    default:
      return state;
  }
}
