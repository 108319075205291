import { HotspotTypes } from '../../../constants';

const validate = values => {
  console.log('values', values);
  const errors = {};
  if (!values.title) {
    errors.title = 'Required field';
  }
  if (values.type === HotspotTypes.IMAGE) {
    if (!values.image) {
      errors.image = 'Add a image';
    }
  }
  if (values.type === HotspotTypes.IFRAME) {
    if (!values.iframe_link) {
      errors.iframe_link = 'Required field';
    }
  }
  if (values.type === HotspotTypes.GALLERY) {
    if (!values.items) {
      errors.items = 'Required field';
    }
  }
  if (values.type === HotspotTypes.FURNITURE) {
    if (!values.image) {
      errors.image = 'Add a image';
    }
  }
  console.log('errors', errors);

  return errors;
};

export default validate;
