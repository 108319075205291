import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Card } from 'react-bootstrap';

import { useDeleteLocation, useRenameLocation } from './hooks';
import { MOVE_OBJECT_TYPE } from '../ContextMenu/ContextMenu';

function LocationItem({
  data,
  clickHandler,
  hash,
  forwardedRef,
  searchName,
  showContextMenu,
  onDelete,
}) {
  const { name, id, description, company_name: companyName } = data;
  const link = `/location/${id}`;

  const deleteLocation = useDeleteLocation({
    locationId: id,
    locationName: description.LOCATION_NAME,
    companyName: description.COMPANY_NAME,
    onDelete,
  });
  const renameLocation = useRenameLocation(id, description, companyName, name);

  const contextMenuHandler = e => {
    e.preventDefault();
    showContextMenu({
      deleteHandler: deleteLocation,
      renameHandler: renameLocation,
      moveContext: { ...data, moveObjectType: MOVE_OBJECT_TYPE.LOCATION },
      canShowMove: true,
      offsetX: e.pageX,
      offsetY: e.pageY,
    });
  };

  return (
    <Col
      xs={12}
      md={4}
      sm={4}
      xl={3}
      lg={3}
      className="location-item mb-4"
      ref={forwardedRef}
    >
      <Link to={link}>
        <Card
          text="dark"
          style={{}}
          onClick={() => clickHandler(data)}
          onContextMenu={contextMenuHandler}
        >
          <div className={`location-item__preview--portfolio`}>
            <div
              className="location-item__background"
              style={{
                backgroundImage: description.IS_INTERACTIVE_PLAN
                  ? `url('${process.env.REACT_APP_MEDIA_URL}/locations/${id}/frames/0/high/frame_1.webp?${hash}')`
                  : `url('${process.env.REACT_APP_MEDIA_URL}/${description.PREVIEW_IMAGE}?${hash}')`,
              }}
            />
          </div>
          <Card.Header
            className="d-flex justify-content-between"
            style={{
              backgroundColor:
                description.STATUS === 'DISABLED'
                  ? 'rgba(255, 0, 0, 0.5)'
                  : undefined,
            }}
          >
            <span
              style={{
                color: searchName === decodeURI(name) ? '#00FF00' : 'black',
              }}
            >
              {decodeURI(name).slice(0, 20)}
              {decodeURI(name).length > 20 && '...'}
            </span>
            <span>
              {description.IS_INTERACTIVE_PLAN
                ? 'Floor Plan'
                : description.INSTANT_PANO_ONLY
                  ? '2D Tour'
                  : '3D Tour'}
            </span>
          </Card.Header>
        </Card>
      </Link>
    </Col>
  );
}

LocationItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  hash: PropTypes.string.isRequired,
};

export default LocationItem;
