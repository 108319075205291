import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FcGoogle } from 'react-icons/fc';

import { loginWithSession, loginWithGoogle, loginWithEmail } from './auth';
import Layout from '../Layout/Layout';
import { authenticate, hideContextMenu } from '../../redux/actions';
import { magic } from '../../shared/utils/magic';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Form = styled.form`
  font-family: Montserrat, sans-serif;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
`;

const Input = styled.input`
  display: block;
  width: 340px;
  border: 0;
  background-color: #f8f9fa;
  transition: all 0.2s ease;
  font-size: 18px;
  padding: 12px 15px;
  color: #343a40;
  border-radius: 10px;
  margin-top: 20px;

  &:focus {
    outline: 0;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
  }

  &::placeholder {
    color: #343a40;
    opacity: 0.4;
    font-size: 20px;
  }
`;

const Button = styled.button`
  width: 340px;
  height: 52px;
  margin-top: 20px;
  background-color: #343a40;
  border: 2px solid #343a40;
  color: #f8f9fa;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 16px;
  border-radius: 10px;

  &:hover {
    color: #343a40;
    background-color: #f8f9fa;
  }

  &:focus {
    outline: 0;
  }
`;

const GoogleButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const GoogleLogInButton = styled.button`
  width: 340px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f8f9fa;
  background-color: #007bff;
  border: 2px solid #007bff;
  border-radius: 10px;
  padding: 10px 20px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f8f9fa;
    color: #007bff;
  }
`;

const StyledGoogleIcon = styled(FcGoogle)`
  margin: 0 15px;
`;

const ShadowBox = styled.div`
  width: 450px;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  background-color: #ffffff;
`;

const Header = styled.h2`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #343a40;
  margin: 0;
`;

const Logo = styled.img`
  width: 170px;
  height: 90px;
  margin-bottom: 30px;
`;

const OrSeparator = styled.div`
  width: 340px;
  border-bottom: 1px solid #000000;
  line-height: 0.1em;
  margin: 36px 0 36px;
  text-align: center;
`;

const OrText = styled.span`
  background: #ffffff;
  padding: 0 20px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
`;

function MagicAuth({ children, dispatch }) {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    loginWithSession()
      .then(user => {
        if (user) {
          setIsLoggedIn(true);
          dispatch(authenticate(user));

          return;
        }

        return loginWithGoogle();
      })
      .then(user => {
        if (user) {
          setIsLoggedIn(true);
          dispatch(authenticate(user));
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    const listener = e => {
      if (!e.composedPath().find(el => el.className === 'context-menu')) {
        dispatch(hideContextMenu());
      }
    };
    document.addEventListener('click', listener);

    return () => document.removeEventListener('click', listener);
  }, [dispatch]);

  if (loading) {
    return (
      <Container>
        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Loading...</div>
      </Container>
    );
  }

  if (isLoggedIn) {
    return <Layout>{children}</Layout>;
  }

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      const user = await loginWithEmail(email);
      if (user) {
        setIsLoggedIn(true);
        dispatch(authenticate(user));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSocialLogin = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      await magic.oauth.loginWithRedirect({
        provider: 'google',
        redirectURI: new URL('/dashboard', window.location.origin).href,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <ShadowBox>
        <Logo
          src={`${process.env.PUBLIC_URL}/img/logoBlack.jpg`}
          alt="Company logo"
        />
        <Header>Welcome back</Header>
        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Input
            type="email"
            placeholder="Email address"
            required
            onChange={e => setEmail(e.currentTarget.value)}
            value={email}
          />
          <Button type="submit">Continue</Button>
        </Form>
        <OrSeparator>
          <OrText>OR</OrText>
        </OrSeparator>
        <GoogleButtonContainer>
          <GoogleLogInButton onClick={handleSocialLogin}>
            <StyledGoogleIcon size={'2rem'} />
            Continue with Google
          </GoogleLogInButton>
        </GoogleButtonContainer>
      </ShadowBox>
    </Container>
  );
}

MagicAuth.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(MagicAuth);
