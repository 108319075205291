import React from 'react';
import PropTypes from 'prop-types';
import HelpCircleIcon from 'mdi-react/HelpCircleIcon';

import WithTooltip from './WithTooltip';

const InfoTooltipIcon = ({ tooltip }) => {
  return (
    <WithTooltip placement="top" content={tooltip}>
      <HelpCircleIcon color="#007bff" style={{ opacity: 0.6 }} />
    </WithTooltip>
  );
};

InfoTooltipIcon.propTypes = {
  tooltip: PropTypes.node.isRequired,
};

export default InfoTooltipIcon;
